"use strict";

var fs = require('fs');
var path = require('path');

var TtmGameMemoryLandscape = function () {
};

TtmGameMemoryLandscape.prototype.scope = {
    object : '=',
    globalConfig : '=',
    objectSetting : '=',
    settingTemplate : '=',
    idTemplate: ' =',
    idType : '=',
    idEntry : '=',
    margin : '=',
    privacy : '='
};

TtmGameMemoryLandscape.prototype.template = fs.readFileSync(path.resolve('views/directives/GameMemoryLandscape.template'), 'utf8');

TtmGameMemoryLandscape.prototype.restrict = 'E';

module.exports = TtmGameMemoryLandscape;