"use strict";
var _ = require('lodash');

var VideoFullController = function ($scope, $rootScope, $location, config, $http, $sce,$state,$stateParams,configLoad,apiFactory) {
    $scope.VAPI = null;

    var promise = configLoad.load($stateParams.language);
    promise.then(
        function(payload) {
            $scope.globalConfig = payload.data;

            //console.log("GLOBAL CONFIG", config);
            $scope.object = {};
            $scope.setting = {};
            $scope.video = {};
            $scope.settingLayout = {};
            ///ID_TEMPLATE
            var id_template = ($scope.globalConfig.previewOnline == false) ? $scope.globalConfig.id_template : $stateParams.id_template;
            var id_brand = ($scope.globalConfig.previewOnline == false) ? $scope.globalConfig.id_brand : $stateParams.id_brand;
            var id_language = ($scope.globalConfig.previewOnline == false) ? $scope.globalConfig.id_language : $stateParams.id_language;

            /////////////PARAMETRI COUCHDB
            var urlServlet = $scope.globalConfig.urlServlet + 'entries?entry_type=' + 1 + '&id=' + $stateParams.id_video + '&id_brand='+id_brand+'&id_template='+id_template;
            var couchDoc = 'entries__'+1+'__'+$stateParams.id_video+'__'+id_brand+'__'+id_template;

            ////CONNESSIONE AL DB, LO SI EFFETTUA SOLO LA PRIMA VOLTA POI VIENE CACHATA
            apiFactory.dbConnect($scope.globalConfig.id_brand,$scope.globalConfig.previewOnline,$scope.globalConfig.id_totem,$stateParams.id_template);

            var promise = apiFactory.api(urlServlet,'formbrand',couchDoc,$scope.globalConfig.previewOnline);
            promise.then(function(data) {
                $scope.object = data.message.dataEntry.children[id_language];
                $scope.setting = data.message.dataEntry.settings;
                $scope.settingLayout = data.message.layout.settings;
                $scope.settingLayout['code'] = data.message.layout.code;
                $scope.id_layout = data.message.layout.id_layout;
                $scope.margin = data.message.layout.settings.padding;

                if($state.current.name=='previewFullVideoPortrait'){
                    $scope.video = $scope.object.video_portrait_obj;
                }
                else{
                    $scope.video = $scope.object.video_landscape_obj;
                }

                if($scope.setting.audioDetail == 0){
                    $scope.VAPI.setVolume(0);
                }

                var url = $scope.video.uri;
                var promiseImage = $rootScope.imageSource(url);
                promiseImage.then(function(data) {
                    $scope.videoSources = [
                        {
                            "src": $sce.trustAsResourceUrl(data),
                            type: "video/mp4"
                        }
                    ];
                });

            }, function(reason) {
                ///redirect
                alert('Failed: ' + reason);
            });

            //////CONTROLLER VIDEO
            $scope.showControls = false;
            $scope.CtrmanageVideoClick = function(){
                if($scope.setting.controlsDetail==1){
                    $scope.showControls= ($scope.showControls) ? false : true;
                }
                else if($scope.setting.controlsDetail==0){ ///stop,play
                    if(this.VAPI.currentState=='stop'){
                        this.VAPI.play();
                    }
                    else{
                        this.VAPI.stop();
                    }
                }
            };
            $scope.themeUrl = "./assets/videogular/videogular.css";
        },
        function(errorPayload) {
            console.log("error",errorPayload);
        });

        /////FULL READY
        $scope.onPlayerReadyFull = function (API) {
            //console.log("PLAYER READY FULL CONTROLLER VIDEO");
            $scope.VAPI = API;
            this.VAPI = API;
        };
};

module.exports = VideoFullController;