"use strict";

var fs = require('fs');
var path = require('path');


var TtmProductRandom = function () {
    //this._http = $http; //Or probably with _ prefix this._http = $http;
};

TtmProductRandom.$inject = ['$http','$stateParams']; //explicit annotation


TtmProductRandom.prototype.scope = {
    entry : '=',
    randomEntry: '=',
    base: '=',
    globalConfig: '=',
    margin: '=',
    objectPromoRandom: '=',
    loaded: '=',
    box: '=',
    placeholderImage : '='
};

TtmProductRandom.prototype.template = fs.readFileSync(path.resolve('views/directives/ProductRandom.template'), 'utf8');

TtmProductRandom.prototype.restrict = 'E';

TtmProductRandom.prototype.controller =  function($scope, $element,$http,$stateParams){
    $scope.loaded = false;
    $scope.presentPromo = false;

    if(angular.isDefined($scope.randomEntry[$scope.entry]) && $scope.loaded==false && angular.isDefined($scope.randomEntry[$scope.entry].entriesRandom) && angular.isDefined($scope.randomEntry[$scope.entry].entriesRandom[$scope.box.id_box]) && $scope.randomEntry[$scope.entry].entriesRandom[$scope.box.id_box].length>0){
        var numberAllKey = Object.keys($scope.randomEntry[$scope.entry].entriesRandom[$scope.box.id_box]).length;
        var keySelect = Math.floor((Math.random() * numberAllKey) + 1);
        $scope.objectPromoRandom = angular.copy($scope.randomEntry[$scope.entry].entriesRandom[$scope.box.id_box][keySelect-1]);
        $scope.randomEntry[$scope.entry].entriesRandom[$scope.box.id_box].splice(keySelect-1,1);
        //console.log("SELECT KEY",keySelect);
        $scope.loaded = true;

        $http({
            method: 'GET',
            url: $scope.globalConfig.urlServlet + 'entries?entry_type='+$scope.entry+'&id='+$scope.objectPromoRandom.id
        }).success(function(data, status, headers, config) {
            $scope.parentObject = data.message.dataEntry;
            $scope.object = data.message.dataEntry.children[$stateParams.id_language];
            //$scope.box = data.message.dataEntry.box_obj;
            $scope.idParent = $scope.objectPromoRandom.id;
            $scope.settingEntry = data.message.dataEntry.settings;
            //console.log("MESSAGE DATA",data.message);
            $scope.presentPromo = true;
        });
    }
    else{
        if($scope.entry==5){
            $scope.placeholderImage = $scope.globalConfig.urlBaseMedia+$scope.randomEntry[$scope.entry].placeholder[$scope.box.id_box].image_obj.uri;
            $scope.presentPromo = false;
        }
    }
};

module.exports = TtmProductRandom;