"use strict";

var PreviewController = function ($scope, $location, config,$sce, $state,$stateParams,$http,configLoad,apiFactory,$timeout,$rootScope) {

    var promise = configLoad.load($stateParams.language);
    promise.then(
        function(payload) {
            //this.config = payload.data;
            //console.log("PREVIEW CONTACT FORM");
            $scope.globalConfig = payload.data;
            $scope.object = {};
            $scope.settingLayout = {};

            $scope.id_parent = $stateParams.id_obj;
            $scope.id_template = $stateParams.id_template;

            /////////////PARAMETRI COUCHDB
            var urlServlet = $scope.globalConfig.urlServlet + 'entries?entry_type='+$stateParams.id_entry_type+'&id='+$stateParams.id_obj+'&id_brand='+$stateParams.id_brand+'&id_template='+$stateParams.id_template;
            var couchDoc = 'entries__'+$stateParams.id_entry_type+'__'+$stateParams.id_obj+'__'+$stateParams.id_brand+'__'+$stateParams.id_template;

            ////CONNESSIONE AL DB, LO SI EFFETTUA SOLO LA PRIMA VOLTA POI VIENE CACHATA
            apiFactory.dbConnect($scope.globalConfig.id_brand,$scope.globalConfig.previewOnline,$scope.globalConfig.id_totem,$stateParams.id_template);

            var promise = apiFactory.api(urlServlet,'entries',couchDoc,$scope.globalConfig.previewOnline);
            promise.then(function(data) {
                console.log("LOAD FUNCTION",data,data.message.dataEntry.settings);
                $scope.dataEntry = $stateParams.id_entry_type;
                $scope.parentObject = data.message.dataEntry;
                $scope.object = angular.isDefined(data.message.dataEntry.children[$stateParams.id_language]) ? data.message.dataEntry.children[$stateParams.id_language] : data.message.dataEntry.children;
                $scope.box = data.message.dataEntry.box_obj;
                $scope.settingLayout = (data.message.layout.settings) ? data.message.layout.settings : {};
                $scope.settingLayout['code'] = (data.message.layout) ? data.message.layout.code : "";
                $scope.settingEntry = data.message.dataEntry.settings;
                console.log("SETTING ENTRY",$scope.settingEntry);
                console.log(data.message.dataEntry.settings);
                //console.log(data.message);
                $timeout.cancel($rootScope.timeoutReturnHome);
                $timeout.cancel($rootScope.timeoutReturnHomeFromDetail);

            }, function(reason) {
                ///redirect
                alert('Failed: ' + reason);
            });
        },
        function(errorPayload) {
            console.log("error",errorPayload);
        });
};

module.exports = PreviewController;