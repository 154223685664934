"use strict";

var ModalOkEmail = function ($scope, $rootScope, $location, config,$http,$sce,$uibModalInstance,configLoad,apiFactory) {
    var promise = configLoad.load();
    promise.then(
        function(payload) {
            $scope.globalConfig = payload.data;

            $scope.ok = function () {
                $uibModalInstance.close("ok");
            };

            $scope.cancel = function () {
                $uibModalInstance.close("cancel");
            };

            $rootScope.$on('$stateChangeStart',
                function (event, toState, toParams, fromState, fromParams) {
                    $uibModalInstance.close("cancel");
                });
        },
        function(errorPayload) {
            console.log("error",errorPayload);
        });

    ///CAMBIAMENTO STATO
    $rootScope.$on('$stateChangeStart',
        function (event, toState, toParams, fromState, fromParams) {
            $uibModalInstance.close("cancel");
        });

};

module.exports = ModalOkEmail;