"use strict";

var PreviewFooterController = function ($scope, $location, config,$sce, $state,$stateParams,$http,configLoad,apiFactory) {
    var promise = configLoad.load();
    promise.then(
        function(payload) {
            $scope.globalConfig = payload.data;
            $scope.object = {};
            $scope.settingLayout = {};

            $http({
                method: 'GET',
                url: $scope.globalConfig.urlServlet + 'entries?entry_type='+$stateParams.id_entry_type+'&id='+$stateParams.id_obj+'&id_brand='+$stateParams.id_brand+'&id_template='+$stateParams.id_template
                //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                //data: {"entry_type":$stateParams.id_entry_type,"id":$stateParams.id_obj} //PARAMETRI PASSATI
            }).success(function(data, status, headers, config) {
                $scope.dataEntry = $stateParams.id_entry_type;
                $scope.object = data.message.dataEntry;
                $scope.settingLayout = data.message.layout;
                $scope.settingLayout['code'] = data.message.layout.code;
                console.log("FOOTER",data.message);
            });

            $scope.random = {};
            //////////////RANDOM
            var defDealer = ($scope.globalConfig.id_totem!=null) ? $scope.globalConfig.id_dealer : 0;
            var promiseRandom = apiFactory.random($scope.globalConfig.urlServlet,$stateParams.id_brand,$stateParams.id_template,defDealer);
            promiseRandom.then(function(data) {
                $scope.random = data;
                console.log("RANDOM",$scope.random);
            });

        },
        function(errorPayload) {
            console.log("error",errorPayload);
        });
};

module.exports = PreviewFooterController;