"use strict";

var fs = require('fs');
var path = require('path');

var TtmSlideshow = function () {
};

TtmSlideshow.prototype.scope = {
    innerFor: '=',
    innerClass: "=",
    innerEvent: "&"
};

TtmSlideshow.prototype.template = fs.readFileSync(path.resolve('views/directives/slideshow.template'), 'utf8');

TtmSlideshow.prototype.restrict = 'E';

module.exports = TtmSlideshow;