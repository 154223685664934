"use strict";
var _ = require('lodash');

//app.controller('HomepageController', ['$scope', '$rootScope', '$location', 'config','$http','$sce','$state','$stateParams','$uibModal','configLoad','ApiFactory', require('../scripts/controllers/HomepageController')]);

var HomepageController = function ($scope, $rootScope, $location, config,$http,$sce,$state,$stateParams,$uibModal,configLoad,apiFactory) {

    //console.log("sto caricando la pagina ==> MARGIN",$rootScope.margin);

    var promise = configLoad.load($stateParams.language);
    promise.then(
        function(payload) {
            $scope.rows = [];
            $scope.settingLayout = {};
            $scope.random = {};
            $rootScope.globalConfig = payload.data;
            $scope.id_language = $stateParams.id_language;


            ////CONNESSIONE AL DB, LO SI EFFETTUA SOLO LA PRIMA VOLTA POI VIENE CACHATA
            apiFactory.dbConnect($scope.globalConfig.id_brand,$scope.globalConfig.previewOnline,$scope.globalConfig.id_totem,$stateParams.id_template);

            $scope.random = {};
            //////////////RANDOM
            var defDealer = ($scope.globalConfig.id_totem!=null) ? $scope.globalConfig.id_dealer : 0;
            var promiseRandom = apiFactory.random($scope.globalConfig.urlServlet,$stateParams.id_brand,$stateParams.id_template,defDealer);
            promiseRandom.then(function(data) {
                $scope.random = data;
                var urlServlet;
                var couchDoc;
                if($stateParams.id_layout!=-1 && $stateParams.id_layout!=''){
                    urlServlet = $scope.globalConfig.urlServlet + 'homepage?id_brand='+$stateParams.id_brand+'&id_templates='+$stateParams.id_template+'&id_associazione='+$stateParams.id_layout;
                    couchDoc = 'homepage__'+$stateParams.id_brand+'__'+$stateParams.id_template+'__'+$stateParams.id_language+'__'+$stateParams.id_layout;
                }
                else{
                    urlServlet = $scope.globalConfig.urlServlet + 'homepage?id_brand='+$stateParams.id_brand+'&id_templates='+$stateParams.id_template;
                    couchDoc = 'homepage__'+$stateParams.id_brand+'__'+$stateParams.id_template+'__'+$stateParams.id_language;
                }


                var promise = apiFactory.api(urlServlet,'homePage',couchDoc,$scope.globalConfig.previewOnline);
                promise.then(function(data) {
                    $scope.presentLayout = angular.isDefined(data.message.layout.id_layout);

                    $scope.id_layout = data.message.layout.id_layout;
                    $rootScope.margin = ($scope.presentLayout) ? data.message.layout.settings.background : 0;
                    $rootScope.credits = parseInt(data.message.layout.settings.credits);
                    $rootScope.settingLayout = ($scope.presentLayout) ? data.message.layout.settings : {};
                    $rootScope.settingLayout['code'] = data.message.layout.code;
                    $rootScope.creditsText = (data.message.layout.infoBrand!=null) ?  data.message.layout.infoBrand.credits : '';
                    $rootScope.styleGlobal($rootScope.settingLayout);
                    $scope.rows = ($scope.presentLayout) ? data.message.hp : [];
                }, function(reason) {
                    ///redirect
                    alert('Failed: ' + reason);
                });
            });
        },
        function(errorPayload) {
            console.log("error",errorPayload);
        });
};

module.exports = HomepageController;
