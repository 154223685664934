"use strict";

var fs = require('fs');
var path = require('path');

var TtmCallToAction = function () {
};

TtmCallToAction.prototype.scope = {
    callList : '=',
    globalConfig : '=',
    settingTemplate : '=',
    idTemplate : '=',
    margin : '=',
    privacy : '=',
    forced : '='
};

TtmCallToAction.prototype.template = fs.readFileSync(path.resolve('views/directives/CallToAction.template'), 'utf8');

TtmCallToAction.prototype.restrict = 'E';

module.exports = TtmCallToAction;