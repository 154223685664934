"use strict";
var _ = require('lodash');

var MeteoController = function ($scope, $rootScope, $location, config, $http, $sce,$state,$stateParams,configLoad,apiFactory) {
  var promise = configLoad.load();
  promise.then(
      function(payload) {
        $scope.globalConfig = payload.data;
      },
      function(errorPayload) {
        console.log("error",errorPayload);
      });
};

module.exports = MeteoController;