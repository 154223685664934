"use strict";

var fs = require('fs');
var path = require('path');

var TtmImageEvidence = function () {
};

TtmImageEvidence.prototype.scope = {
    uri : '=',
    position : '=',
    setting : '=',
    style : '@',
    base: '=',
    children: '=',
    entry : '=',
    layout: '=',
    box: '=',
    idCategory: '=',
    parent: '=',
    margin: '=',
    textMore: '=',
    globalConfig: '=',
    swipe: '='
};

TtmImageEvidence.prototype.template = fs.readFileSync(path.resolve('views/directives/image.template'), 'utf8');
TtmImageEvidence.prototype.restrict = 'E';
TtmImageEvidence.prototype.controller =  function($scope, $rootScope,$element,$http,$stateParams,$timeout){
    $scope.loadImage = false;
    $scope.loadObject = false;

    $scope.$watch(
        function (newValue, oldValue) {
            function loadImage(){
                ////IMAGE
                var promiseImage = $rootScope.imageSource($scope.uri);
                promiseImage.then(function(data) {
                    //console.log("arrived inside directive",data);
                    $scope.uri = angular.copy(data);
                    $scope.loadImage = true;

                    console.log($scope.uri);

                    $rootScope.$broadcast('loadImage',{uri: data});
                });
            }

            if(!$scope.loadObject && angular.isDefined(newValue.uri) && $scope.swipe!=1){
                $scope.loadObject = true;
                loadImage();
            }

            if($scope.swipe==1)
                $scope.loadImage = true;
        }
    );
};
TtmImageEvidence.prototype.link = function (scope, element, attrs, controller, transcludeFn) {
    scope.loadImage = false;
    scope.$watch(
        function (newValue, oldValue) {
            if(angular.isUndefined(scope.uri)){
                scope.imagePresent = false;
            }
            else{
                scope.imagePresent = true;

                /////FONTSTYLE
                if(angular.isDefined(newValue.setting)){
                    switch(newValue.setting.fontStyle) {
                        case 1:
                            scope.classFont = 'large';
                            break;
                        case 2:
                            scope.classFont = 'medium';
                            break;
                        case 3:
                            scope.classFont = 'small';
                            break;
                        default:
                            scope.classFont='medium';
                    }
                }
                else{
                    scope.classFont='medium';
                }

                /////FONTSTYLE
                if(angular.isDefined(newValue.layout)){
                    switch(newValue.layout.fontType) {
                        case 1:
                            scope.fontFamily = 'fontArial';
                            break;
                        case 2:
                            scope.fontFamily = 'fontRci';
                            break;
                        default:
                            scope.fontFamily='fontArial';
                    }
                }
                else{
                    scope.fontFamily='arial';
                }

                /////VERTICALALIGN
                if(angular.isDefined(newValue.setting)){
                    switch(newValue.setting.textVAlignTitleHP) {
                        case 1:
                            scope.verticalAlign = 'top';
                            break;
                        case 2:
                            scope.verticalAlign = 'middle';
                            break;
                        case 3:
                            scope.verticalAlign = 'bottom';
                            break;
                        default:
                            scope.verticalAlign='top';
                    }
                }
                else{
                    scope.verticalAlign='top';
                }

                /////FONTSTYLE
                if(angular.isDefined(newValue.setting)){
                    switch(newValue.setting.fontStyle) {
                        case 1:
                            scope.classFont = 'large';
                            break;
                        case 2:
                            scope.classFont = 'medium';
                            break;
                        case 3:
                            scope.classFont = 'small';
                            break;
                        default:
                            scope.classFont='medium';
                    }
                }
                else{
                    scope.classFont='medium';
                }

                /////TEXTALIGN
                if(angular.isDefined(newValue.setting)){
                    switch(newValue.setting.textAlignTitleHP) {
                        case 1:
                            scope.textAlign = 'left';
                            break;
                        case 2:
                            scope.textAlign = 'center';
                            break;
                        case 3:
                            scope.textAlign = 'right';
                            break;
                        default:
                            scope.textAlign='left';
                    }
                }
                else{
                    scope.textAlign='left';
                }

                // RIDIMENSIONAMENTO AUTOMATICO
                var percentageDim = (newValue.box.name=='F1') ? 15 : 33;

                if(scope.swipe!=1){
                    if(newValue.position=='1' || newValue.position=='2'){
                        scope.tipologia = (scope.margin==0) ? "grid_vertical" : "grid_vertical_margin";
                        scope.heightGrid = (angular.isDefined(newValue.box)) ? newValue.box.height-20 : 88;
                        //scope.widthGrid = (angular.isDefined(newValue.box)) ? (newValue.box.width/100*30)-10 : 88;
                        scope.widthGrid = '100%';

                        scope.top = 'auto';
                        scope.bottom = 'auto';
                        scope.left = (newValue.position=='1') ? 0 : 'auto';
                        scope.right = (newValue.position=='2') ? 0 : 'auto';
                    }
                    else{
                        scope.tipologia = "grid_orizzontal";
                        scope.heightGrid = (angular.isDefined(newValue.box)) ? newValue.box.height/100*percentageDim-20 : 88;
                        scope.widthGrid = (angular.isDefined(newValue.box)) ? newValue.box.width-10 : 88;

                        scope.left = 'auto';
                        scope.right = 'auto';
                        scope.top = (newValue.position=='3') ? 0 : 'auto';
                        scope.bottom = (newValue.position=='4') ? 0 : 'auto';
                    }
                }
                else{
                    if(newValue.position=='1' || newValue.position=='2'){
                        scope.tipologia = (scope.margin==0) ? "grid_vertical" : "grid_vertical_margin";
                        var sottrazione = (scope.margin==0) ? 30 : 50;
                        scope.heightGrid = (angular.isDefined(newValue.box)) ? newValue.box.height-sottrazione : 88;
                        //scope.widthGrid = (angular.isDefined(newValue.box)) ? (newValue.box.width/100*30)-10 : 88;
                        scope.widthGrid = '100%';

                        scope.top = 'auto';
                        scope.bottom = 'auto';
                        scope.left = (newValue.position=='1') ? 0 : 'auto';
                        scope.right = (newValue.position=='2') ? 0 : 'auto';
                    }
                    else{
                        scope.tipologia = "grid_orizzontal";
                        scope.heightGrid = (angular.isDefined(newValue.box)) ? newValue.box.height/100*percentageDim-20 : 88;
                        scope.widthGrid = (angular.isDefined(newValue.box)) ? newValue.box.width-10 : 88;

                        scope.left = 'auto';
                        scope.right = 'auto';
                        scope.top = (newValue.position=='3') ? 0 : 'auto';
                        scope.bottom = (newValue.position=='4') ? 0 : 'auto';
                    }
                }
            }
        }
    );

};

module.exports = TtmImageEvidence;