"use strict";
var fs = require('fs');
var path = require('path');
var PouchDB = require('pouchdb');

var StatsLocal = function($http,$q) {
    //https://totem:totem01@rciapp.3punto6.com/appdb/app__db__48
    var dbName = '';
    var localDB = null;
    var remoteDB = null;
    var statsLocal = {};
    var previewValue = 0;
    var random = {};

    /////////DB CONNECT
    statsLocal.dbConnect = function(id_totem){
        var dbNameNew = 'stats__'+id_totem;
        ///////MI COLLEGO NUOVAMENTE SOLO SE MI VOGLIO COLLEGARE AD UN NUOVO DB, SOLO SE NON E' UNA PREVIEW LEGATA AL CMS
        if(dbName!=dbNameNew && previewValue != -1){
            localDB =  new PouchDB('http://localhost:5984/'+dbNameNew);
            dbName=dbNameNew;
            //localDB =  new PouchDB('http://totem.linkomm.net:5984/'+dbNameNew);
        }
    };

    //////API FACTORY
    statsLocal.put = function(title,json){
        json.timestamp = new Date().getTime().toString();
        localDB.put({
            _id: title,
            toapp: json
        }).then(function (response) {
            //console.log("RESPONSE",response);
            // handle response
        }).catch(function (err) {
            console.log(err);
        });
    };

    return statsLocal;
};

module.exports = StatsLocal;