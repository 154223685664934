"use strict";

var PreviewContactForm = function ($scope, $rootScope,$location, config,$sce, $state,$stateParams,$http,configLoad,apiFactory) {
    console.log("PREVIEW CONTACT FORM s",$stateParams);
    // dsadsa
    var promise = configLoad.load($stateParams.language);
    promise.then(
        function(payload) {
            //this.config = payload.data;
            //console.log("PREVIEW CONTACT FORM");
            $rootScope.globalConfig = payload.data;
            $scope.object = {};
            $scope.settingLayout = {};
            $scope.arrayFooter = [];
            $scope.arrayLabel = [];
            $scope.arrayPrivacy = [];
            $scope.id_brand = $stateParams.id_brand;
            $scope.dataEntry = $stateParams.id_entry_type;
            $scope.id_template = $stateParams.id_template;
            $scope.id_language = $stateParams.id_language;

            //1080x840
            $scope.obj = ($stateParams.id_template==2) ? {'width':1080,'height':600} : {'width':1080,'height':300};
            $scope.obj_content = ($stateParams.id_template==2) ? {'width':1080,'height':840} : {'width':1080,'height':840};
            $scope.image = '';
            var id_associazione = ($stateParams.id_layout=='') ? '' : '&id_associazione='+$stateParams.id_layout;

            ////CONNESSIONE AL DB, LO SI EFFETTUA SOLO LA PRIMA VOLTA POI VIENE CACHATA
            apiFactory.dbConnect($scope.globalConfig.id_brand,$scope.globalConfig.previewOnline,$scope.globalConfig.id_totem,$stateParams.id_template);

            $rootScope.$on('sendedEmailContactForm', function(event, args) {
                    console.log("RESETTO I VALORI DEL CONTACT FORM");
                    angular.forEach($scope.arrayLabel, function (value, key) {
                        value.value=null;
                    });
                }
            );

            $scope.random = {};
            //////////////RANDOM
            var defDealer = ($scope.globalConfig.id_totem!=null) ? $scope.globalConfig.id_dealer : 0;
            var promiseRandom = apiFactory.random($scope.globalConfig.urlServlet,$stateParams.id_brand,$stateParams.id_template,defDealer);
            promiseRandom.then(function(data) {
                $scope.random = data;
                /////////////PARAMETRI COUCHDB
                var urlServlet = $scope.globalConfig.urlServlet + 'formBrand?id_brand='+$stateParams.id_brand+'&id_templates='+$stateParams.id_template+'&id_language='+$stateParams.id_language+'&id_contact_cover='+$stateParams.id_obj+id_associazione;
                var couchDoc = 'formbrand__'+$stateParams.id_brand+'__'+$stateParams.id_template+'__'+$stateParams.id_language+'__'+$stateParams.id_obj;

                var promise = apiFactory.api(urlServlet,'formbrand',couchDoc,$scope.globalConfig.previewOnline);
                promise.then(function(data) {
                    $scope.presentLayout = angular.isDefined(data.message.layout.id_layout);
                    $scope.arrayLabel = data.message.formBrand.fields;
                    $scope.settingLayout = data.message.layout.settings;
                    $scope.settingLayout['code'] = data.message.layout.code;
                    $scope.parent = data.message.formBrand;
                    $scope.arrayPrivacy = angular.copy(data.message.layout.privacy);
                    $rootScope.margin = ($scope.presentLayout) ? data.message.layout.settings.background : 0;
                    $rootScope.credits = parseInt(data.message.layout.settings.credits);
                    $rootScope.settingLayout = ($scope.presentLayout) ? data.message.layout.settings : {};
                    $rootScope.creditsText = (data.message.layout.infoBrand!=null) ?  data.message.layout.infoBrand.credits : '';
                    $scope.textIntro = (angular.isDefined(data.message.contactFormCover.id) && angular.isDefined(data.message.contactFormCover.children[$stateParams.id_language])) ? data.message.contactFormCover.children[$stateParams.id_language].textIntro : null;
                    $rootScope.styleGlobal($rootScope.settingLayout);
                    //console.log("TEXT INTRO",$scope.textIntro);
                    /////////IMAGE
                    if($stateParams.id_template==1){
                        $scope.image = (angular.isDefined(data.message.contactFormCover.id) && angular.isDefined(data.message.contactFormCover.children[$stateParams.id_language]) && data.message.contactFormCover.children[$stateParams.id_language].id_image_landscape!=null) ? $scope.globalConfig.urlBaseMedia+data.message.contactFormCover.children[$stateParams.id_language].image_landscape_obj.uri : './assets/img/contact-360.jpg';
                    }
                    else{
                        $scope.image = (angular.isDefined(data.message.contactFormCover.id) && angular.isDefined(data.message.contactFormCover.children[$stateParams.id_language]) && data.message.contactFormCover.children[$stateParams.id_language].id_image_portrait!=null) ? $scope.globalConfig.urlBaseMedia+data.message.contactFormCover.children[$stateParams.id_language].image_portrait_obj.uri : './assets/img/contact-600.jpg';
                    }
                    //console.log("PRIVACY",$scope.arrayPrivacy);
                }, function(reason) {
                    ///redirect
                    alert('Failed: ' + reason);
                });

                ////FOOTER
                var urlServletFooter = $scope.globalConfig.urlServlet + 'footer?entry_type='+12+'&id_brand='+$stateParams.id_brand+'&id_template='+$stateParams.id_template;
                var couchDocFooter = 'footer__'+12+'__'+$stateParams.id_brand+'__'+$stateParams.id_template;

                var promiseFooter = apiFactory.api(urlServletFooter,'footer',couchDocFooter);
                promiseFooter.then(function(data) {
                    $scope.arrayFooter = data.message.box;
                }, function(reason) {
                    ///redirect
                    alert('Failed: ' + reason);
                });
            });

        },
        function(errorPayload) {
            console.log("error",errorPayload);
        });
    };

module.exports = PreviewContactForm;