"use strict";
var _ = require('lodash');
var PouchDB = require('pouchdb');

//app.controller('HomepageController', ['$scope', '$rootScope', '$location', 'config','$http','$sce','$state','$stateParams','$uibModal','configLoad','ApiFactory', require('../scripts/controllers/HomepageController')]);

var ProximityController = function ($scope, $rootScope, $location, config,$http,$sce,$state,$stateParams,$uibModal,configLoad,apiFactory,$websocket) {

    $scope.proximity = {};
    //////IMAGE E VIDEO
    $scope.imageUrl = '';
    $scope.videoUrl = '';
    $scope.videoSources = [];
    $scope.redirectHome = false;

    this.onPlayerReady = function (API) {
        this.VAPI = API;
        this.VAPI.setVolume(0);
    };

    var promise = configLoad.load($stateParams.language);
    promise.then(
        function(payload) {
            $scope.rows = [];
            $scope.settingLayout = {};
            $scope.random = {};
            $scope.globalConfig = payload.data;

            //////TEMPLATE CONFIGURATION
            var id_brand = $scope.globalConfig.id_brand;
            var id_template = $scope.globalConfig.id_template;
            /////////////PARAMETRI COUCHDB
            var urlServlet = $scope.globalConfig.urlServlet+'proximity?id_brand='+id_brand+'&id_templates='+id_template;
            var couchDoc = 'proximity__'+id_brand+'__'+id_template;
            ////CONNESSIONE AL DB, LO SI EFFETTUA SOLO LA PRIMA VOLTA POI VIENE CACHATA
            apiFactory.dbConnect($scope.globalConfig.id_brand,$scope.globalConfig.previewOnline,$scope.globalConfig.id_totem,$stateParams.id_template);

            var promise = apiFactory.api(urlServlet,'proximity',couchDoc,$scope.globalConfig.previewOnline);
            promise.then(function(data) {
                $scope.proximity = data.message;
                if(data.message.type=='image'){
                    var promiseImage = $rootScope.imageSource(data.message.img_obj.uri);
                    promiseImage.then(function(data) {
                        $scope.imageUrl = data;
                    });
                }
                else{
                    var promiseImage = $rootScope.imageSource(data.message.video_obj.uri);
                    promiseImage.then(function(data) {
                        $scope.videoSources = [
                            {
                                "src": $sce.trustAsResourceUrl(data),
                                type: "video/mp4"
                            }
                        ];
                    });
                }
            }, function(reason) {
                ///redirect
                alert('Failed: ' + reason);
            });

            ////////////////
            $rootScope.ws.$on('$message',function (message) {
                //console.log("DISTANZA WEBSOCKET",parseInt(message),$scope.globalConfig.proximityFrom,$scope.globalConfig.proximityTo); // it prints 'dude'
                if(parseInt(message)>$scope.globalConfig.proximityFrom && parseInt(message)<$scope.globalConfig.proximityTo){
                    if($scope.redirectHome==false){
                        //console.log("HOME BUTTON");
                        $scope.redirectHome = true;
                        $rootScope.sendStatistiche = true;
                        $rootScope.homeButton();
                    }
                }
            });
        },
        function(errorPayload) {
            console.log("error",errorPayload);
        });
};

module.exports = ProximityController;