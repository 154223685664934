"use strict";
var _ = require('lodash');

var VideoPlayerController = function ($scope, $rootScope, $location, config, $http, $sce,$state,$stateParams,configLoad,apiFactory) {
  var promise = configLoad.load($stateParams.language);
  promise.then(
      function(payload) {
          $scope.videoReady = false;

          $scope.globalConfig = payload.data;
        $scope.themeUrl = "./assets/videogular/videogular.css";

        if($scope.url){
            var promiseImage = $rootScope.imageSource($scope.url,$scope.notRelative);
            promiseImage.then(function(data) {
                $scope.videoSources = [
                    {
                        "src": $sce.trustAsResourceUrl(data),
                        type: "video/mp4"
                    }
                ];

                $scope.videoReady = true;
            });
        }
        else{
          $scope.videoSources = [];
        }
      },
      function(errorPayload) {
        console.log("error",errorPayload);
      });

    this.onPlayerReady = function (API) {
        this.VAPI = API;
        if($scope.mute==='true' || $scope.mute==true){
            this.VAPI.setVolume(0);
        }
        else{
            this.VAPI.setVolume(50);
        }
    };
};

module.exports = VideoPlayerController;