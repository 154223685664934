"use strict";

var fs = require('fs');
var path = require('path');

var TtmHomePagePortraitLess = function () {
    //this.compile = $compile;
};

TtmHomePagePortraitLess.prototype.scope = {
    rows : '=',
    globalConfig : '=',
    settingTemplate : '=',
    idTemplate : '=',
    templateurl : '=',
    idLanguage : '=',
    margin : '=',
    random: '='
};


TtmHomePagePortraitLess.prototype.template = fs.readFileSync(path.resolve('views/home/3_portrait.template'), 'utf8');
TtmHomePagePortraitLess.prototype.restrict = 'E';
module.exports = TtmHomePagePortraitLess;
