"use strict";

var fs = require('fs');
var path = require('path');

var TtmParserHtml = function () {
};

TtmParserHtml.prototype.scope = {
    html : '='
};

TtmParserHtml.$inject = ['$compile']; //explicit annotation

TtmParserHtml.prototype.template = fs.readFileSync(path.resolve('views/directives/ParserHtml.template'), 'utf8');

TtmParserHtml.prototype.restrict = 'E';

TtmParserHtml.prototype.controller =  function($scope, $rootScope,$element,$http,$stateParams,$timeout,$compile){
    return {
        compileHtml: function(){
            $element.append($scope.html);
            $compile($element.contents())($scope);
        }
    }
};

TtmParserHtml.prototype.compile = function compile( tElement, tAttributes, transcludeFn ) {
    // Compile code goes here.
    return {
        pre: function preLink( scope, element, attributes, controller, transcludeFn ) {
            // Pre-link code goes here
        },
        post: function postLink( scope, element, attributes, controller, transcludeFn) {
            controller.compileHtml();
        }
    };
};

module.exports = TtmParserHtml;