"use strict";

var fs = require('fs');
var path = require('path');

var TtmMeteo = function () {
};

TtmMeteo.prototype.scope = {
    globalConfig : '=',
    idTemplate : '=',
    margin : '=',
    box : '='
};

TtmMeteo.prototype.template = fs.readFileSync(path.resolve('views/directives/Meteo.template'), 'utf8');

TtmMeteo.prototype.restrict = 'E';

TtmMeteo.prototype.controller =  function($scope, $element,$http,$stateParams,$state, $location){
    $scope.loaded = false;

    $scope.cityMeteo = {};
    $scope.meteoCurr = {};
    $scope.meteoOth = [];
    $scope.localita = null;
    $scope.online = true;

    function dayOfWeekAsString(dayIndex) {
        if(angular.isUndefined($scope.globalConfig.dayWeek)){
            return '';
        }
        else
            return $scope.globalConfig.dayWeek[dayIndex];
    }


    var monthsInYear= $scope.globalConfig.monthYear;

    /////NUMBER METEO
    var countDays = 1;
    if($scope.box.name=='2X'){
        countDays = 3;
    }
    else if($scope.box.name=='3X' || $scope.box.name=='A' || $scope.box.name=='B1'){
        countDays = 5;
    }
    else if($scope.box.name=='B2'){
        countDays = 4;
    }

    $scope.changeCity = function(element){
        //////SEND STATS CLICK
        if($scope.globalConfig.statsCounter){
            //console.log("STATS COUNTER");
            var dataSend = {
                'id_brand': $scope.globalConfig.id_brand,
                'id_dealer': $scope.globalConfig.id_dealer,
                'id_totem': $scope.globalConfig.id_totem,
                'id_entry_type': 17,
                'id_entry': 0,
                'hP': 0,
                'FP': 0
            };
            ////chiamata post
            $http({
                method: 'POST',
                url: $scope.globalConfig.urlServlet + 'statsclick',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                data: dataSend //PARAMETRI PASSATI
            }).success(function (data, status, headers, config) {
                //console.log("DATA RETURN CHANGE STATE", data);
            });
        }

        ////////RICHIESTA DI METEO => LAT E LONG
        $http({
            method: 'GET',
            url: '//api.openweathermap.org/data/2.5/forecast/daily?q='+element+'&mode=json&units=metric&cnt='+countDays+'&appid='+$scope.globalConfig.apiMeteo+'&lang='+$scope.globalConfig.languageCodeMeteo,
        }).success(function(data, status, headers, config) {
            console.log("DATA RETURN",data);
            $scope.cityMeteo = data.city;
            $scope.meteoOth = [];
            angular.forEach(data.list, function(value, key) {
                var d = new Date(value.dt*1000);
                var dayTradotta = dayOfWeekAsString(d.getDay());
                var myString = "";
                myString += d.getDate() + " ";
                myString += monthsInYear[d.getMonth()] + " ";
                myString += d.getFullYear();
                value.dayTradotta = dayTradotta;
                value.dateTradotta = myString;
                ////aggiungo
                if(key==0){
                    $scope.meteoCurr = value;
                }
                else{
                    $scope.meteoOth.push(value);
                }
            });
        });
    };

    ////////RICHIESTA DI METEO => LAT E LONG
    //url: 'http://api.openweathermap.org/data/2.5/forecast/daily?lat='+$scope.globalConfig.lat+'&lon='+$scope.globalConfig.lon+'&mode=json&units=metric&cnt='+countDays+'&appid='+$scope.globalConfig.apiMeteo+'&lang='+$scope.globalConfig.languageCodeMeteo,
    $http({
        method: 'GET',
        url: '//api.openweathermap.org/data/2.5/forecast/daily?q='+$scope.globalConfig.cityMeteo+'&mode=json&units=metric&cnt='+countDays+'&appid='+$scope.globalConfig.apiMeteo+'&lang='+$scope.globalConfig.languageCodeMeteo,
    }).success(function(data, status, headers, config) {
        $scope.cityMeteo = data.city;
        if($scope.box.name=='1X') $scope.localita = data.city.name;
        $scope.meteoOth = [];
        angular.forEach(data.list, function(value, key) {
            var d = new Date(value.dt*1000);
            var dayTradotta = dayOfWeekAsString(d.getDay());
            var myString = "";
            myString += d.getDate() + " ";
            myString += monthsInYear[d.getMonth()] + " ";
            myString += d.getFullYear();
            value.dayTradotta = dayTradotta;
            value.dateTradotta = myString;
            ////aggiungo
            if(key==0){
                $scope.meteoCurr = value;
            }
            else{
                $scope.meteoOth.push(value);
            }
        });
        })
        .error(function(data, status, headers, config) {
            $scope.online = false;
        })
    ;


};

module.exports = TtmMeteo;
