"use strict";

var fs = require('fs');
var path = require('path');

var TtmSwitchDataEntry = function () {
};

TtmSwitchDataEntry.prototype.scope = {
    globalConfig : '=',
    settingLayout : '=',
    idTemplate : '=',
    margin : '=',
    object : '=',
    idLanguage : '=',
    entry : '=',
    idParent: '=',
    settingEntry : '=',
    box : '=',
    parentEntry: '=',
    random : '=',
    randomEntry : '='
};

TtmSwitchDataEntry.prototype.controller =  function($scope, $rootScope,$element,$http,$stateParams,$state,$timeout) {
    $scope.$watch('object',
        function (newValue, oldValue) {
                if(angular.isDefined(newValue) && angular.isDefined(newValue.image_obj) && angular.isArray(newValue.image_obj)){
                    angular.forEach(newValue.image_obj, function (value, key) {
                        var promiseImage = $rootScope.imageSource(value.uri);
                        promiseImage.then(function(data) {
                            value.checkUri = data;
                        });
                    });
                }
                else if(angular.isDefined(newValue) && angular.isDefined(newValue.elements)){
                    angular.forEach(newValue.elements, function (value, key) {
                        var promiseImage = $rootScope.imageSource(value.image.uri);
                        promiseImage.then(function(data) {
                            value.image.checkUri = data;
                        });
                    });
                }
            }
        );

};

TtmSwitchDataEntry.prototype.template = fs.readFileSync(path.resolve('views/directives/SwitchDataEntry.template'), 'utf8');

TtmSwitchDataEntry.prototype.restrict = 'E';

module.exports = TtmSwitchDataEntry;