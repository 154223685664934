"use strict";

var fs = require('fs');
var path = require('path');
var moment = require('moment');

var TtmRooms = function () {
};

TtmRooms.prototype.scope = {
    globalConfig: '=',
    idTemplate: '=',
    margin: '=',
    box: '='
};

TtmRooms.prototype.template = fs.readFileSync(path.resolve('views/directives/Rooms.template'), 'utf8');

TtmRooms.prototype.restrict = 'E';

TtmRooms.prototype.controller = function ($scope, $element, $http, $stateParams, $state, $location) {
    var endDate = new Date();
    endDate.setHours(24);
    endDate.setMinutes(59);

    $scope.rooms = [];
    //////HTTP SEND CALL
    $http({
        method: 'GET',
        url: $scope.globalConfig.rooms + '/events/rooms?take=3&datetime_end='+moment(endDate).utc().toISOString()+'&datetime_start='+moment().toISOString()
    }).success(function (data, status, headers, config) {
        $scope.rooms = data;
    }).error(function (data, status, headers, config) {
    });

    $scope.detailRooms = function(){
        window.location.href= "/#/previewDetailPortrait/4/220/2/143/1/147/it";
    }
};

module.exports = TtmRooms;
