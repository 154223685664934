"use strict";

var PreviewRandomController = function ($scope, $location, config,$sce, $state,$stateParams,$http,configLoad,apiFactory) {

    var promise = configLoad.load();
    promise.then(
        function(payload) {
            $scope.globalConfig = config;
            console.log("START PREVIEW RANDOM CONTROLLER");
            //$scope.object = {};
            //$scope.settingLayout = {};
            console.log("OBJECT RANDOM",$scope.objectPromoRandom);
        },
        function(errorPayload) {
            console.log("error",errorPayload);
        });
    //$scope.id_parent = $stateParams.id_obj;
    //$scope.id_template = $stateParams.id_template;
    //
    //$http({
    //    method: 'GET',
    //    url: this.config.urlServlet + 'entries?entry_type='+$stateParams.id_entry_type+'&id='+$stateParams.id_obj+'&id_brand='+$stateParams.id_brand+'&id_template='+$stateParams.id_template
    //    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    //    data: {"entry_type":$stateParams.id_entry_type,"id":$stateParams.id_obj} //PARAMETRI PASSATI
    //}).success(function(data, status, headers, config) {
    //    $scope.dataEntry = $stateParams.id_entry_type;
    //    $scope.parentObject = data.message.dataEntry;
    //    $scope.object = data.message.dataEntry.children[$stateParams.id_language];
    //    $scope.box = data.message.dataEntry.box_obj;
    //    $scope.settingLayout = data.message.layout.settings;
    //    $scope.settingEntry = data.message.dataEntry.settings;
    //    console.log(data.message);
    //});


};

module.exports = PreviewRandomController;