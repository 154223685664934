"use strict";
var fs = require('fs');

var Routes = function ($stateProvider, $urlRouterProvider) {

    $urlRouterProvider.otherwise("/totem");
  //
  // Now set up the states
    $stateProvider
        .state('totem', {
          url: "/totem",
          //templateUrl: "views/demo_image.html",
          controller:'BaseController',
            onEnter: function($state,$rootScope,configLoad){
                var promise = configLoad.load($state.params.language);
                promise.then(
                    function(payload) {
                        var globalConfig = payload.data;
                        if(globalConfig.id_template==2){
                            //$state.go('previewHpPortrait', {id_template:globalConfig.id_template,id_brand:globalConfig.id_brand,id_language:globalConfig.id_language,id_layout:globalConfig.id_layout},{reload: true, inherit: true, notify: true});
                            $state.go('previewHpPortrait', {id_template:globalConfig.id_template,id_brand:globalConfig.id_brand,id_language:globalConfig.id_language,id_layout:globalConfig.id_layout,language: globalConfig.languageCodeMeteo});
                        }
                        else{
                            //$state.go('previewHpLandScape', {id_template:globalConfig.id_template,id_brand:globalConfig.id_brand,id_language:globalConfig.id_language,id_layout:globalConfig.id_layout},{reload: true, inherit: true, notify: true});
                            $state.go('previewHpLandScape', {id_template:globalConfig.id_template,id_brand:globalConfig.id_brand,id_language:globalConfig.id_language,id_layout:globalConfig.id_layout,language: globalConfig.languageCodeMeteo});
                        }
                    }
                );
            }
        })
        .state('proximityPortraitNoLang', {
            url: "/proximityPortrait/:id_template/:id_brand/:id_language/:id_layout",
            templateUrl: "views/proximity/proximityPortrait.html",
            controller: "ProximityController"
        })
        .state('proximityLandScapeNoLang', {
            url: "/proximityLandScape/:id_template/:id_brand/:id_language/:id_layout",
            templateUrl: "views/proximity/proximityLandscape.html",
            controller: "ProximityController"
        })
        .state('previewEvidenceNoLang', {
            url: "/previewEvidence/:id_entry_type/:id_obj/:id_template/:id_brand/:id_language/:id_layout",
            templateUrl: "views/preview/preview.html",
            controller:'PreviewController'
        })
        .state('previewDetailPortraitNoLang', {
            url: "/previewDetailPortrait/:id_entry_type/:id_obj/:id_template/:id_brand/:id_language/:id_layout",
            templateUrl: "views/preview/previewSecondLevelPortrait.html",
            controller: "PreviewSecondLevelController"
        })
        .state('previewDetailLandScapeNoLang', {
            url: "/previewDetailLandScape/:id_entry_type/:id_obj/:id_template/:id_brand/:id_language/:id_layout",
            templateUrl: "views/preview/previewSecondLevelLandscape.html",
            controller: "PreviewSecondLevelController"
        })
        .state('previewThirdPortraitNoLang', {
          url: "/previewThirdPortrait/:id_category/:id_template/:id_brand/:id_language/:id_layout",
          templateUrl: "views/preview/previewThirdLevelPortrait.html",
          controller: "PreviewThirdLevelController"
        })
        .state('previewThirdLandScapeNoLang', {
          url: "/previewThirdLandScape/:id_category/:id_template/:id_brand/:id_language/:id_layout",
          templateUrl: "views/preview/previewThirdLevelLandscape.html",
          controller: "PreviewThirdLevelController"
        })
        .state('previewHpPortraitNoLang', {
            url: "/previewHpPortrait/:id_template/:id_brand/:id_language/:id_layout",
            templateUrl: "views/preview/previewHomePortrait.html",
            controller: "HomepageController"
        })
        .state('previewHpLandScapeNoLang', {
            url: "/previewHpLandScape/:id_template/:id_brand/:id_language/:id_layout",
            templateUrl: "views/preview/previewHomeLandscape.html",
            controller: "HomepageController"
        })
        .state('previewContactFormPortraitNoLang', {
            url: "/previewContactFormPortrait/:id_template/:id_brand/:id_language/:id_obj/:id_layout",
            templateUrl: "views/preview/previewContactFormPortrait.html",
            controller: "PreviewContactForm"
        })
        .state('previewContactFormLandScapeNoLang', {
            url: "/previewContactFormLandScape/:id_template/:id_brand/:id_language/:id_obj/:id_layout",
            templateUrl: "views/preview/previewContactFormLandscape.html",
            controller: "PreviewContactForm"
        })
        .state('previewFullVideoPortraitNoLang', {
            url: "/previewFullVideoPortrait/:id_template/:id_brand/:id_language/:id_layout/:id_video_children/:id_video",
            templateUrl: "views/video/video_portrait.html",
            controller: "VideoFullController"
        })
        .state('previewFullVideoLandScapeNoLang', {
            url: "/previewFullVideoLandScape/:id_template/:id_brand/:id_language/:id_layout/:id_video_children/:id_video",
            templateUrl: "views/video/video_landscape.html",
            controller: "VideoFullController"
        })
        //////PREVIEW WITH LANGUAGE URL
        .state('proximityPortrait', {
            url: "/proximityPortrait/:id_template/:id_brand/:id_language/:id_layout/:language",
            templateUrl: "views/proximity/proximityPortrait.html",
            controller: "ProximityController"
        })
        .state('proximityLandScape', {
            url: "/proximityLandScape/:id_template/:id_brand/:id_language/:id_layout/:language",
            templateUrl: "views/proximity/proximityLandscape.html",
            controller: "ProximityController"
        })
        .state('previewEvidence', {
            url: "/previewEvidence/:id_entry_type/:id_obj/:id_template/:id_brand/:id_language/:id_layout/:language",
            templateUrl: "views/preview/preview.html",
            controller:'PreviewController'
        })
        .state('previewDetailPortrait', {
            url: "/previewDetailPortrait/:id_entry_type/:id_obj/:id_template/:id_brand/:id_language/:id_layout/:language",
            templateUrl: "views/preview/previewSecondLevelPortrait.html",
            controller: "PreviewSecondLevelController"
        })
        .state('previewDetailLandScape', {
            url: "/previewDetailLandScape/:id_entry_type/:id_obj/:id_template/:id_brand/:id_language/:id_layout/:language",
            templateUrl: "views/preview/previewSecondLevelLandscape.html",
            controller: "PreviewSecondLevelController"
        })
        .state('previewThirdPortrait', {
            url: "/previewThirdPortrait/:id_category/:id_template/:id_brand/:id_language/:id_layout/:language",
            templateUrl: "views/preview/previewThirdLevelPortrait.html",
            controller: "PreviewThirdLevelController"
        })
        .state('previewThirdLandScape', {
            url: "/previewThirdLandScape/:id_category/:id_template/:id_brand/:id_language/:id_layout/:language",
            templateUrl: "views/preview/previewThirdLevelLandscape.html",
            controller: "PreviewThirdLevelController"
        })
        .state('previewHpPortrait', {
            url: "/previewHpPortrait/:id_template/:id_brand/:id_language/:id_layout/:language",
            templateUrl: "views/preview/previewHomePortrait.html",
            controller: "HomepageController"
        })
        .state('previewHpLandScape', {
            url: "/previewHpLandScape/:id_template/:id_brand/:id_language/:id_layout/:language",
            templateUrl: "views/preview/previewHomeLandscape.html",
            controller: "HomepageController"
        })
        .state('previewContactFormPortrait', {
            url: "/previewContactFormPortrait/:id_template/:id_brand/:id_language/:id_obj/:id_layout/:language",
            templateUrl: "views/preview/previewContactFormPortrait.html",
            controller: "PreviewContactForm"
        })
        .state('previewContactFormLandScape', {
            url: "/previewContactFormLandScape/:id_template/:id_brand/:id_language/:id_obj/:id_layout/:language",
            templateUrl: "views/preview/previewContactFormLandscape.html",
            controller: "PreviewContactForm"
        })
        .state('previewFullVideoPortrait', {
            url: "/previewFullVideoPortrait/:id_template/:id_brand/:id_language/:id_layout/:id_video_children/:id_video/:language",
            templateUrl: "views/video/video_portrait.html",
            controller: "VideoFullController"
        })
        .state('previewFullVideoLandScape', {
            url: "/previewFullVideoLandScape/:id_template/:id_brand/:id_language/:id_layout/:id_video_children/:id_video/:language",
            templateUrl: "views/video/video_landscape.html",
            controller: "VideoFullController"
        })
        /////ERROR
        .state('error',{
          url: "/error",
          templateUrl: "views/error.html"
        });
};

module.exports = Routes;
