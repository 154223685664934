"use strict";

var fs = require('fs');
var path = require('path');

var TtmContactForm = function () {
};

TtmContactForm.prototype.scope = {
    innerFor: '=',
    innerClass: "=",
    margin: "=",
    settingTemplate: "=",
    idTemplate: "=",
    textIntro: "=",
    innerEvent: "&",
    arrayLabel: "=",
    idBrand : "=",
    obj: "=",
    objContent: "=",
    image: "=",
    privacy: "=",
    parent: "="
};

TtmContactForm.prototype.template = fs.readFileSync(path.resolve('views/directives/contactForm.template'), 'utf8');

TtmContactForm.prototype.restrict = 'E';

module.exports = TtmContactForm;