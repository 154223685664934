"use strict";


var PreviewSecondLevelController = function ($scope, $rootScope,$location, config,$sce, $state,$stateParams,$http,configLoad,apiFactory) {
    var promise = configLoad.load($stateParams.language);
    promise.then(
        function(payload) {
            //console.log("FILE CONFIG",payload.data);
            //$scope.globalConfig = payload.data;
            //console.log("PREVIEW CONTACT FORM");
            $rootScope.globalConfig = payload.data;
            $scope.object = {};
            $scope.settingLayout = {};
            $scope.arrayFooter = [];
            $scope.arrayPrivacy = [];
            $scope.iScrollParameters = {vScroll:true, scrollbars: false, click: true};
            $scope.id_template = $stateParams.id_template;
            $scope.id_language = $stateParams.id_language;
            $scope.random = {};

            /////
            //console.log("ID LAYOUT",$stateParams.id_layout);
            var id_associazione = ($stateParams.id_layout=='') ? '' : '&id_associazione='+$stateParams.id_layout;
            var urlServlet = $scope.globalConfig.urlServlet + 'entries?entry_type='+$stateParams.id_entry_type+'&id='+$stateParams.id_obj+'&id_brand='+$stateParams.id_brand+'&id_template='+$stateParams.id_template+id_associazione;
            var couchDoc = 'entries__'+$stateParams.id_entry_type+'__'+$stateParams.id_obj+'__'+$stateParams.id_brand+'__'+$stateParams.id_template;

            ////CONNESSIONE AL DB, LO SI EFFETTUA SOLO LA PRIMA VOLTA POI VIENE CACHATA
            apiFactory.dbConnect($scope.globalConfig.id_brand,$scope.globalConfig.previewOnline,$scope.globalConfig.id_totem,$stateParams.id_template);

            //////////////RANDOM
            var defDealer = ($scope.globalConfig.id_totem!=null) ? $scope.globalConfig.id_dealer : 0;
            var promiseRandom = apiFactory.random($scope.globalConfig.urlServlet,$stateParams.id_brand,$stateParams.id_template,defDealer);
            promiseRandom.then(function(data) {
                $scope.random = angular.copy(data);
                var promise = apiFactory.api(urlServlet,'entries',couchDoc,$scope.globalConfig.previewOnline);
                promise.then(function(data) {
                    $scope.presentLayout = angular.isDefined(data.message.layout.id_layout);

                    $scope.dataEntry = $stateParams.id_entry_type;
                    $scope.object = data.message.dataEntry.children[$stateParams.id_language];
                    $scope.id_type = data.message.dataEntry.children[$stateParams.id_language].id_type;
                    $scope.objectSetting = data.message.dataEntry.settings;
                    $scope.settingLayout = data.message.layout.settings;
                    $scope.settingLayout['code'] = data.message.layout.code;
                    $scope.id_entry = data.message.dataEntry.id_entry_type;
                    $rootScope.margin = ($scope.presentLayout) ? data.message.layout.settings.background : 0;
                    $rootScope.credits = parseInt(data.message.layout.settings.credits);
                    $rootScope.settingLayout = ($scope.presentLayout) ? data.message.layout.settings : {};
                    $rootScope.creditsText = (data.message.layout.infoBrand!=null) ?  data.message.layout.infoBrand.credits : '';
                    // console.log("SETTING LAYOUT",$rootScope.settingLayout);
                    $scope.arrayPrivacy = angular.copy(data.message.layout.privacy);
                    $rootScope.styleGlobal($rootScope.settingLayout);
                }, function(reason) {
                    ///redirect
                    alert('Failed: ' + reason);
                });

                var defIDEntry = ($stateParams.id_entry_type==18 || $stateParams.id_entry_type==19) ? 4 : $stateParams.id_entry_type;
                var urlServletFooter = $scope.globalConfig.urlServlet + 'footer?entry_type='+defIDEntry+'&id_brand='+$stateParams.id_brand+'&id_template='+$stateParams.id_template;
                var couchDocFooter = 'footer__'+defIDEntry+'__'+$stateParams.id_brand+'__'+$stateParams.id_template;

                var promiseFooter = apiFactory.api(urlServletFooter,'footer',couchDocFooter);
                promiseFooter.then(function(data) {
                    $scope.arrayFooter = data.message.box;
                }, function(reason) {
                    ///redirect
                    alert('Failed: ' + reason);
                });

            });
        },
        function(errorPayload) {
            console.log("error",errorPayload);
        });
};

module.exports = PreviewSecondLevelController;