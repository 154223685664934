"use strict";

var PreviewThirdLevelController = function ($scope,$rootScope, $location, config,$sce, $state,$stateParams,$http,$filter,configLoad,apiFactory) {
    var promise = configLoad.load($stateParams.language);
    promise.then(
        function(payload) {
            $rootScope.globalConfig = payload.data;
            $scope.arrayProdotti = [];
            $scope.settingLayout = {};
            var id_associazione = ($stateParams.id_layout=='') ? '' : '&id_associazione='+$stateParams.id_layout;
            $scope.boxOBJ = {id_box: 1, name: "1X", width: 360, height: 360};
            $scope.margin = 0;

            ///VARIABILI CAUCH
            var urlServlet = $scope.globalConfig.urlServlet + 'collection?id_category='+$stateParams.id_category+'&id_brand='+$stateParams.id_brand+'&id_template='+$stateParams.id_template+'&id_language='+$stateParams.id_language+id_associazione;
            var couchDoc = 'collection__'+$stateParams.id_category+'__'+$stateParams.id_brand+'__'+$stateParams.id_template+'__'+$stateParams.id_language;

            ////CONNESSIONE AL DB, LO SI EFFETTUA SOLO LA PRIMA VOLTA POI VIENE CACHATA
            apiFactory.dbConnect($scope.globalConfig.id_brand,$scope.globalConfig.previewOnline,$scope.globalConfig.id_totem,$stateParams.id_template);

            var promise = apiFactory.api(urlServlet,'collection',couchDoc,$scope.globalConfig.previewOnline);
            promise.then(function(data) {
                if(data.status=='ok'){
                    $scope.presentLayout = angular.isDefined(data.message.layout.id_layout);
                    var limitTo = ($stateParams.id_template==2) ? 15 : 12;
                    //$scope.arrayProdotti = $filter('limitTo')(data.message.collection.row, limitTo, $stateParams.page*limitTo);
                    $scope.arrayProdotti = data.message.collection.row;
                    //console.log("ARRAY PRODOTTI",$scope.arrayProdotti);
                    $rootScope.margin = ($scope.presentLayout) ? data.message.layout.settings.background : 0;
                    $rootScope.credits = parseInt(data.message.layout.settings.credits);
                    $rootScope.settingLayout = ($scope.presentLayout) ? data.message.layout.settings : {};
                    $scope.settingLayout['code'] = data.message.layout.code;
                    $rootScope.creditsText = (data.message.layout.infoBrand!=null) ?  data.message.layout.infoBrand.credits : '';
                }
                $scope.id_template=$stateParams.id_template;
            }, function(reason) {
                ///redirect
                alert('Failed: ' + reason);
            });

            $scope.random = {};
            //////////////RANDOM
            var defDealer = ($scope.globalConfig.id_totem!=null) ? $scope.globalConfig.id_dealer : 0;
            var promiseRandom = apiFactory.random($scope.globalConfig.urlServlet,$stateParams.id_brand,$stateParams.id_template,defDealer);
            promiseRandom.then(function(data) {
                $scope.random = data;
                console.log("RANDOM",$scope.random);
            });
        },
        function(errorPayload) {
            console.log("error",errorPayload);
        });
};

module.exports = PreviewThirdLevelController;