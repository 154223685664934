"use strict";

var Logger = function () {

};

Logger.prototype.debug = function (data) { };
Logger.prototype.warning = function (data) { };
Logger.prototype.error = function (data) { };

module.exports = Logger;