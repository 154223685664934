"use strict";
var _ = require('lodash');

var VideoController = function ($scope, $rootScope, $location, config, $http, $sce,$state,$stateParams,configLoad,apiFactory) {

  var promise = configLoad.load($stateParams.language);
  promise.then(
      function(payload) {
        $scope.globalConfig = payload.data;

        ////////IS VIDEO
        if($scope.setting.isVideo==0){ ///non c'e' video
          $scope.playVideo = false;
        }
        else if($scope.setting.autoplayEvidence==0){ //non c'e' video e non è in autoplay
          $scope.playVideo = false;
        }
        else{ ////non c'e' l'autoplay e non c'e' il video
          $scope.playVideo = true;
        }

        $scope.fullScreen = function(){
          ////GESTIONE VARIABILI TOTEM
          var id_template = ($scope.globalConfig.previewOnline == false) ? $scope.globalConfig.id_template : $stateParams.id_template;
          var id_brand = ($scope.globalConfig.previewOnline == false) ? $scope.globalConfig.id_brand : $stateParams.id_brand;
          var id_language = ($scope.globalConfig.previewOnline == false) ? $scope.globalConfig.id_language : $stateParams.id_language;
          var id_layout = ($scope.globalConfig.previewOnline == false) ? $scope.globalConfig.id_layout : $stateParams.id_layout;

          if(id_template==2){
            $state.go('previewFullVideoPortrait', {id_template:id_template,id_brand:id_brand,id_language:id_language,id_layout:id_layout,id_video_children:$scope.object.id_children,id_video:$scope.idParent}, {reload: true});
          }
          else{
            $state.go('previewFullVideoLandScape', {id_template:id_template,id_brand:id_brand,id_language:id_language,id_layout:id_layout,id_video_children:$scope.object.id_children,id_video:$scope.idParent}, {reload: true});
          }
        };

        $scope.playButton = function(){
          //////CONFIG VIDEO
          // console.log("PLAY BUTTON CLICK",$scope.setting);
          if($scope.setting.isVideo==1){
            // console.log("HO IL VIDEO IN HP");
            ////start video
            $scope.playVideo = true;
            $scope.setting.autoplayEvidence = true;
            // console.log("BROADCAST",$scope.object.video_evidence_obj.uri);
            $rootScope.$broadcast('playVideo',$scope.object);
          }
          else if($scope.setting.fullscreen==1){ //////fullscreen
            $scope.fullScreen();
          }
        };

        $scope.changeValue = function(){
          if(angular.isDefined($scope.$parent.active))
            $scope.playVideo = false;
        };

        $scope.$parent.$watch('active',function(oldvalue,newvalue){
          $scope.changeValue();
        });

        $scope.showControls = false;
        $scope.themeUrl = "./assets/videogular/videogular.css";

        if($scope.setting.isVideo==1 && $scope.object.video_evidence_obj!=null){
            var promiseImage = $rootScope.imageSource($scope.object.video_evidence_obj.uri);
            promiseImage.then(function(data) {
                $scope.videoSources = [
                    {
                        "src": $sce.trustAsResourceUrl(data),
                        type: "video/mp4"
                    }
                ];
            });
        }
        else{
          $scope.videoSources = [];
        }
      },
      function(errorPayload) {
        console.log("error",errorPayload);
      });

    $scope.$on('full_screen_video', function(event, args) {
            if(args.videoid==$scope.object.id_children){
                $scope.fullScreen();
            }
        }
    );

    this.completeVideo = function(){
        $rootScope.$broadcast('stopVideo',$scope.object);
    };

    this.onPlayerReady = function (API) {
        this.VAPI = API;
        //console.log("HO IL PLAYER ATTIVO");
        // console.log($scope.setting);
        ////RESETTO IL VOLUME PERCHE' NON HO AUDIO
        if($scope.setting.audioEvidence == 0){
            this.VAPI.setVolume(0);
        }
        else{
            this.VAPI.setVolume(50);
        }
    };

    this.CtrmanageVideoClick = function(){
        if($scope.setting.controlsEvidence==1){
            $scope.showControls= ($scope.showControls) ? false : true;
        }
        else if($scope.setting.controlsEvidence==0 && $scope.setting.fullscreen==1){ ///vado in fullscreen
            $scope.fullScreen();
        }
        else if($scope.setting.controlsEvidence==0 && $scope.setting.fullscreen==0){ ///stop,play
            //if(this.VAPI.currentState=='stop'){
            //  this.VAPI.play();
            //}
            //else{
            //  this.VAPI.stop();
            //}
        }
    };
};

module.exports = VideoController;