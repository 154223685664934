"use strict";

var fs = require('fs');
var path = require('path');

var TtmHomePageLandscapeModular = function () {
    //this.compile = $compile;
};

TtmHomePageLandscapeModular.prototype.scope = {
    rows : '=',
    globalConfig : '=',
    settingTemplate : '=',
    idTemplate : '=',
    templateurl : '=',
    idLanguage : '=',
    margin : '=',
    random: '='
};


TtmHomePageLandscapeModular.prototype.template = fs.readFileSync(path.resolve('views/home/3_landscape.template'), 'utf8');
TtmHomePageLandscapeModular.prototype.restrict = 'E';
module.exports = TtmHomePageLandscapeModular;
