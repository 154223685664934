"use strict";

var fs = require('fs');
var path = require('path');

var TtmGameMemoryPortrait = function () {
};

TtmGameMemoryPortrait.prototype.scope = {
    object : '=',
    globalConfig : '=',
    objectSetting : '=',
    settingTemplate : '=',
    idTemplate: ' =',
    idType : '=',
    idEntry : '=',
    margin : '=',
    privacy : '='
};

TtmGameMemoryPortrait.prototype.template = fs.readFileSync(path.resolve('views/directives/GameMemoryPortrait.template'), 'utf8');

TtmGameMemoryPortrait.prototype.restrict = 'E';

module.exports = TtmGameMemoryPortrait;