"use strict";

var ModalMoreInfo = function ($scope, $rootScope, $location, config,$http,$sce,$uibModalInstance,call,privacy,configLoad,apiFactory) {
    //console.log("PRIVACY",privacy);
    $scope.showPrivacy = false;
    $scope.textPrivacy = "";
    $scope.fieldsCustom = angular.copy(call.fieldsCustom);
    $scope.privacy = angular.copy(privacy); ///privacy regole
    var promise = configLoad.load();
    promise.then(
        function(payload) {
            $scope.globalConfig = payload.data;
            $scope.call = angular.copy(call);
            $scope.moreInfo = {};
            $scope.moreInfo.other = {};
            $scope.moreInfo.call = call.id;
            $scope.moreInfo.id_totem = ($scope.globalConfig.id_totem==null) ? 0 : $scope.globalConfig.id_totem;
            $scope.moreInfo.note = "";
            //console.log("CALL ACTION",call);
            $scope.ok = function () {
                /////CUSTOM
                //console.log($scope.fieldsCustom);
                angular.forEach($scope.fieldsCustom, function (value, key) {
                    $scope.moreInfo.other[value.label] = value.value;
                });
                /////PRIVACY
                angular.forEach($scope.privacy, function (value, key) {
                    $scope.moreInfo.other[value.title] = (angular.isUndefined(value.accepted) || !value.accepted ) ? "not checked" : "checked";
                });
                //////////////////////////
                $http({
                    method: 'POST',
                    url: $scope.globalConfig.urlServlet + 'sendemail',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    data: $scope.moreInfo //PARAMETRI PASSATI
                }).success(function(data, status, headers, config) {
                    //console.log("DATA RETURN",data);
                    if(data.status=='ok'){
                        $rootScope.openSuccessEmail();
                    }
                })
                .error(function(data, status, headers, config) {
                        $rootScope.openErrorEmail();
                    });
                $uibModalInstance.close("ok");
            };

            $scope.cancel = function () {
                $uibModalInstance.close("cancel");
            };
        },
        function(errorPayload) {
            console.log("error",errorPayload);
        });

        $scope.enablePrivacy = function(privacy){
            $scope.showPrivacy = true;
            $scope.textPrivacy = privacy.privacy;
        };

        $scope.disablePrivacy = function(){
            $scope.showPrivacy = false;
        };

    ///CAMBIAMENTO STATO
    $rootScope.$on('$stateChangeStart',
        function (event, toState, toParams, fromState, fromParams) {
            $uibModalInstance.close("cancel");
        });

};

module.exports = ModalMoreInfo;