/* global value */
'use strict';

var angular = require('angular');
var _ = require('lodash');
// var es2015 = require('babel-preset-es2015');

require('babel-plugin-transform-es2015-function-name');
require('moment');
require('angular-moment');
require('moment-interval');
require('moment-timezone');
require('angular-animate');
require('angular-ui-router');
require('angular-bootstrap');
//require('slick-carousel');
//require('angular-slick');
require('angular-sanitize');
require('angular-virtual-keyboard');
require('videogular');
require('vg-controls');
require('vg-overlay-play');
require('vg-poster');
require('vg-buffering');
require('angular-loading-bar');
require('ng-websocket');
require('hammerjs');
require('angular-hammer-recognizers');
require('jquery-fancybox');
//require('overflow-scroll');
//require('pouchdb');
//require('iscroll');

//require('angular-momentum-scroll');
//require('angular-iscroll');
//require('jquery');
//require('stringformat').extendString('replaceParameters');
var fs = require('fs');

var Promise = require('bluebird'),
    uuid = require('uuid'),
    bunny = require('bunnycry'),
    BaseBootstrapper = bunny.Bootstrapper,
    DirectiveCreator = bunny.angular.DirectiveCreator,
    directiveCreator = new DirectiveCreator(),
    app = new BaseBootstrapper();

//Global
app.value('config', 'oldConfig');

//app.directive('iconPointer', [directiveCreator.create(require('../scripts/directives/IconPointer'))]);
app.directive('ttmImageEvidence', [directiveCreator.create(require('../scripts/directives/imageEvidence'))]);
app.directive('ttmSlideshow', [directiveCreator.create(require('../scripts/directives/slideshow'))]);
app.directive('ttmSwipe', [directiveCreator.create(require('../scripts/directives/swipe'))]);
app.directive('ttmVideo', [directiveCreator.create(require('../scripts/directives/video'))]);
app.directive('ttmFooter', [directiveCreator.create(require('../scripts/directives/footer'))]);
app.directive('ttmSidebar', [directiveCreator.create(require('../scripts/directives/sidebar'))]);
app.directive('ttmDetailSecondLevel', [directiveCreator.create(require('../scripts/directives/DetailSecondLevel'))]);
app.directive('ttmCallToAction', [directiveCreator.create(require('../scripts/directives/CallToAction'))]);
app.directive('ttmListCategory', [directiveCreator.create(require('../scripts/directives/ListCategory'))]);
app.directive('ttmSwitchDataEntry', [directiveCreator.create(require('../scripts/directives/SwitchDataEntry'))]);
app.directive('ttmHomePagePortraitLess', [directiveCreator.create(require('../scripts/directives/HomePagePortraitLess'))]);
app.directive('ttmHomePagePortraitModular', [directiveCreator.create(require('../scripts/directives/HomePagePortraitModular'))]);
app.directive('ttmHomePagePortraitPrivilege', [directiveCreator.create(require('../scripts/directives/HomePagePortraitPrivilege'))]);
app.directive('ttmHomePageLandscapeLess', [directiveCreator.create(require('../scripts/directives/HomePageLandscapeLess'))]);
app.directive('ttmHomePageLandscapeModular', [directiveCreator.create(require('../scripts/directives/HomePageLandscapeModular'))]);
app.directive('ttmHomePageLandscapePrivilege', [directiveCreator.create(require('../scripts/directives/HomePageLandscapePrivilege'))]);
app.directive('ttmContactForm', [directiveCreator.create(require('../scripts/directives/ContactForm'))]);
app.directive('ttmMeteo', [directiveCreator.create(require('../scripts/directives/Meteo'))]);
app.directive('ttmRooms', [directiveCreator.create(require('../scripts/directives/Rooms'))]);
app.directive('ttmProductRandom', [directiveCreator.create(require('../scripts/directives/ProductRandom'))]);
app.directive('ttmGameMemoryPortrait', [directiveCreator.create(require('../scripts/directives/GameMemoryPortrait'))]);
app.directive('ttmGameMemoryLandscape', [directiveCreator.create(require('../scripts/directives/GameMemoryLandscape'))]);
app.directive('ttmVideoPlayer', [directiveCreator.create(require('../scripts/directives/VideoPlayer'))]);
app.directive('ttmParserHtml', ['$compile',directiveCreator.create(require('../scripts/directives/ParserHtml'))]);
//app.directive('scrollableDiv', ['$timeout', '$window', '$document', directiveCreator.create(require('../scripts/directives/Scrollable'))]);

//Routes
//app.config('$routeProvider', ['$routeProvider', '$locationProvider', require('../config/routes')]);
app.config('$stateProvider', ['$stateProvider','$urlRouterProvider', require('../config/uiroutes')]);
app.controller('BaseController', ['$scope', '$rootScope', '$location', 'config','$http','$sce','$state','$stateParams','$uibModal','$interval','$timeout','$websocket','configLoad','apiFactory','statsLocal','$q','$element', require('../scripts/controllers/BaseController')]);
app.controller('PreviewController', ['$scope','$location','config','$sce','$state','$stateParams','$http','configLoad','apiFactory','$timeout','$rootScope', require('../scripts/controllers/PreviewController')]);
app.controller('PreviewRandomController', ['$scope','$location','config','$sce','$state','$stateParams','$http','configLoad','apiFactory', require('../scripts/controllers/PreviewRandomController')]);
app.controller('PreviewSecondLevelController', ['$scope','$rootScope','$location','config','$sce','$state','$stateParams','$http','configLoad','apiFactory', require('../scripts/controllers/PreviewSecondLevelController')]);
app.controller('PreviewThirdLevelController', ['$scope','$rootScope','$location','config','$sce','$state','$stateParams','$http','$filter','configLoad','apiFactory', require('../scripts/controllers/PreviewThirdLevelController')]);
app.controller('PreviewFooterController', ['$scope','$rootScope','$location','config','$sce','$state','$stateParams','$http','configLoad','apiFactory', require('../scripts/controllers/PreviewFooterController')]);
app.controller('PreviewContactForm', ['$scope','$rootScope','$location','config','$sce','$state','$stateParams','$http','configLoad','apiFactory', require('../scripts/controllers/PreviewContactForm')]);
app.controller('ModalIstanceMoreInfo', ['$scope', '$rootScope', '$location', 'config','$http','$sce','$uibModalInstance','call','privacy','configLoad','apiFactory', require('../scripts/controllers/ModalMoreInfo')]);
app.controller('ModalCredits', ['$scope', '$rootScope', '$location', 'config','$http','$sce','$uibModalInstance','creditsTextModal','configLoad','apiFactory', require('../scripts/controllers/ModalCredits')]);
app.controller('ModalOkEmail', ['$scope', '$rootScope', '$location', 'config','$http','$sce','$uibModalInstance','configLoad','apiFactory', require('../scripts/controllers/ModalOkEmail')]);
app.controller('ModalInstancePrivacy', ['$scope', '$rootScope', '$location', 'config','$http','$sce','$uibModalInstance','privacyLabel','globalConfig','configLoad','apiFactory', require('../scripts/controllers/ModalInstancePrivacy')]);
app.controller('HomepageController', ['$scope', '$rootScope', '$location', 'config','$http','$sce','$state','$stateParams','$uibModal','configLoad','apiFactory', require('../scripts/controllers/HomepageController')]);
app.controller('ProximityController', ['$scope', '$rootScope', '$location', 'config','$http','$sce','$state','$stateParams','$uibModal','configLoad','apiFactory','$websocket', require('../scripts/controllers/ProximityController')]);
app.controller('VideoController', ['$scope', '$rootScope', '$location', 'config','$http','$sce','$state','$stateParams','configLoad','apiFactory', require('../scripts/controllers/VideoController')]);
app.controller('VideoPlayerController', ['$scope', '$rootScope', '$location', 'config','$http','$sce','$state','$stateParams','configLoad','apiFactory', require('../scripts/controllers/VideoPlayerController')]);
app.controller('VideoFullController', ['$scope', '$rootScope', '$location', 'config','$http','$sce','$state','$stateParams','configLoad','apiFactory', require('../scripts/controllers/VideoFullController')]);
app.controller('MeteoController', ['$scope', '$rootScope', '$location', 'config','$http','$sce','$state','$stateParams','configLoad','apiFactory', require('../scripts/controllers/MeteoController')]);

///FACTORY
app.factory('configLoad', ['$http','$q',require('../scripts/factories/ConfigLoad')]);
app.factory('apiFactory', ['$http','$q',require('../scripts/factories/ApiFactory')]);
app.factory('statsLocal', ['$http','$q',require('../scripts/factories/StatsLocal')]);

module.exports = app;
