"use strict";

var fs = require('fs');
var path = require('path');

var TtmListCategory = function () {
};

TtmListCategory.prototype.scope = {
    object : '=',
    globalConfig : '=',
    settingTemplate : '=',
    box : '=',
    arrayProdotti : '=',
    idTemplate: ' =',
    idType : '=',
    margin : '='
};

TtmListCategory.prototype.template = fs.readFileSync(path.resolve('views/directives/ListCategory.template'), 'utf8');

TtmListCategory.prototype.restrict = 'E';

module.exports = TtmListCategory;