"use strict";

var fs = require('fs');
var path = require('path');

var TtmDetailSecondLevel = function () {
};

TtmDetailSecondLevel.prototype.scope = {
    object : '=',
    globalConfig : '=',
    objectSetting : '=',
    settingTemplate : '=',
    idTemplate: ' =',
    idType : '=',
    idEntry : '=',
    margin : '=',
    privacy : '='
};

TtmDetailSecondLevel.prototype.template = fs.readFileSync(path.resolve('views/directives/DetailSecondLevel.template'), 'utf8');

TtmDetailSecondLevel.prototype.restrict = 'E';

TtmDetailSecondLevel.prototype.controller =  function($scope, $rootScope,$element,$http,$stateParams,$timeout,$compile){
    $scope.loadImage = false;
    $scope.loadObject = false;

    $scope.$watch(
        function (newValue, oldValue) {
            function loadImage(){
                ////IMAGE
                if(angular.isDefined($scope.object.id_image_detail_obj) && $scope.object.id_image_detail_obj!=null){
                    var promiseImage = $rootScope.imageSource($scope.object.id_image_detail_obj.uri);
                    promiseImage.then(function(data) {
                        $scope.object.id_image_detail_obj.uri = angular.copy(data);
                        $scope.loadImage = true;
                    });
                }
                if(angular.isDefined($scope.object.id_image_detail_page_obj) && $scope.object.id_image_detail_page_obj!=null){
                    var promiseImage = $rootScope.imageSource($scope.object.id_image_detail_page_obj.uri);
                    promiseImage.then(function(data) {
                        $scope.object.id_image_detail_page_obj.uri = angular.copy(data);
                        $scope.loadImage = true;
                    });
                }
                if(angular.isDefined($scope.object.id_image_detail_page_landscape_obj) && $scope.object.id_image_detail_page_landscape_obj!=null){
                    var promiseImageLandscape = $rootScope.imageSource($scope.object.id_image_detail_page_landscape_obj.uri);
                    promiseImageLandscape.then(function(data) {
                        $scope.object.id_image_detail_page_landscape_obj.uri = angular.copy(data);
                        $scope.loadImage = true;
                    });
                }
            }

            if(!$scope.loadObject && angular.isDefined($scope.object) && angular.isDefined($scope.object.id_children)){
                $scope.loadObject = true;
                $timeout(loadImage, 100);
            }
        }
    );
};


module.exports = TtmDetailSecondLevel;