"use strict";
var _ = require('lodash');
var fs = require('fs');

var BaseController = function ($scope, $rootScope, $location, config, $http, $sce, $state, $stateParams, $uibModal, $interval, $timeout, $websocket, configLoad, apiFactory, statsLocal, $q,$element) {
    $rootScope.ws = null;
    $rootScope.loadImageCounter = 0;
    $rootScope.styleGlobalReturn = '';

    console.log("BASE CONTROLLER",$stateParams);

    var promise = configLoad.load($stateParams.language,false);
    promise.then(
        function (payload) {
            /////API CONNECT
            var globalConfig = payload.data;

            ////CONNESSIONE AL DB, LO SI EFFETTUA SOLO LA PRIMA VOLTA POI VIENE CACHATA
            apiFactory.dbConnect(globalConfig.id_brand,globalConfig.previewOnline,globalConfig.id_totem,$stateParams.id_template);

            $rootScope.imageSource = function(urlImage,notBaseUrl){
                return $q(function (resolve, reject) {
                    var promiseImage = apiFactory.image(urlImage);
                    promiseImage.then(function(data) {
                        if(angular.isUndefined(notBaseUrl) || notBaseUrl==false)
                            data = (data==urlImage) ? globalConfig.urlBaseMedia+data : data;
                        //console.log("DATA",data);
                        resolve(data);
                    }, function(reason) {
                        ///redirect
                        alert('Failed: ' + reason);
                    });
                });
            };

            $rootScope.trustedHtml = function (plainText) {
                return $sce.trustAsHtml(plainText);
            };

            /////////////CONTROLLO STATISTICHE
            $rootScope.sendStatistiche = true;

            //DB
            $rootScope.checkTypeEntry = function (entry, object) {
                if (entry == 10 && object.image_evidence == 1) {
                    return true;
                }
                return (entry != 10 && entry != 8 && entry != 1 && entry != 22 && entry != 16 && entry != 17 && entry!=18 && entry!=19 && entry!=20 && entry!=21);
            };

            ///////CHECK
            $rootScope.checkLabel = function (label) {
                //console.log("label",label);
                return label == 'Firstname' || label == 'Lastname' || label == 'Email';
            };

            ///////////VARIABILI GLOBALI SIDEBAR
            $rootScope.settingLayout = null;
            $rootScope.margin = null;
            $rootScope.credits = null;
            $rootScope.creditsText = null;
            $rootScope.loadSettingGlobal = false;
            $rootScope.id_layout = (globalConfig.previewOnline == false) ? globalConfig.id_layout : $state.params.id_layout;
            //console.log("ID LAYOUT",globalConfig.previewOnline,$rootScope.id_layout);

            /////REDIRECT
            $rootScope.effectiveChange = function (click, entry, parent) {
                //console.log("CLICK");
                $rootScope.sendStatistiche = true;

                var id_template = (globalConfig.previewOnline == false) ? globalConfig.id_template : $state.params.id_template;
                var id_brand = (globalConfig.previewOnline == false) ? globalConfig.id_brand : $state.params.id_brand;
                var id_language = (globalConfig.previewOnline == false) ? globalConfig.id_language : $state.params.id_language;
                var id_layout = (globalConfig.previewOnline == false) ? globalConfig.id_layout : $state.params.id_layout;
                var language = ($state.params.language) ? $state.params.language : globalConfig.languageCodeMeteo;

                $rootScope.id_templates = $stateParams.id_template;

                if (entry == 6 || entry == 7 || entry == 8 || entry == 2 || entry == 4 || entry == 10 || entry == 5 || entry == 18 || entry == 19) { ///product + pages
                    //console.log("ENTRY PRODUCT");
                    ///ID TEMPLATE ==> portrait
                    if (id_template == 2) {
                        $state.go('previewDetailPortrait', {
                            'id_entry_type': entry,
                            'id_obj': parent,
                            'id_template': id_template,
                            'id_brand': id_brand,
                            'id_language': id_language,
                            'id_layout': id_layout,
                            'language' : language
                        }, {reload: true});
                    }
                    else {
                        $state.go('previewDetailLandScape', {
                            'id_entry_type': entry,
                            'id_obj': parent,
                            'id_template': id_template,
                            'id_brand': id_brand,
                            'id_language': id_language,
                            'id_layout': id_layout,
                            'language' : language
                        }, {reload: true});
                    }
                }
                //////////////COLLECTION
                else if (entry == 3) {
                    //console.log("ENTRY COLLECTION");
                    if (id_template == 2) {
                        $state.go('previewThirdPortrait', {
                            'id_category': click.id_category,
                            'id_template': id_template,
                            'id_brand': id_brand,
                            'id_language': id_language,
                            'id_layout': id_layout,
                            'language' : language
                        }, {reload: true});
                    }
                    else {
                        $state.go('previewThirdLandScape', {
                            'id_category': click.id_category,
                            'id_template': id_template,
                            'id_brand': id_brand,
                            'id_language': id_language,
                            'id_layout': id_layout,
                            'language' : language
                        }, {reload: true});
                    }
                }
                else if (entry == 12) {
                    if (id_template == 2) {
                        $state.go('previewContactFormPortrait', {
                            'id_template': id_template,
                            'id_brand': id_brand,
                            'id_language': id_language,
                            'id_layout': id_layout,
                            'language' : language,
                            'id_obj': parent
                        }, {reload: true});
                    }
                    else {
                        $state.go('previewContactFormLandScape', {
                            'id_template': id_template,
                            'id_brand': id_brand,
                            'id_language': id_language,
                            'id_layout': id_layout,
                            'language' : language,
                            'id_obj': parent
                        }, {reload: true});
                    }
                }
            };

            $rootScope.changePage = function (click, entry, parent) {
                // console.log("CLICK",click);
                // console.log("ENTRY",entry);
                // console.log("PARENT",parent);
                //if ($state.current.name == 'previewDetailPortrait' && angular.isDefined(parent) && $stateParams.id_obj != parent) {
                if ($state.current.name == 'previewDetailPortrait' && (angular.isUndefined(parent) || $stateParams.id_obj != parent)) {
                    $rootScope.effectiveChange(click, entry, parent);
                }
                else if ($state.current.name != 'previewDetailPortrait') {
                    $rootScope.effectiveChange(click, entry, parent);
                }
            };

            ////IMG CONCORSO
            $rootScope.imgConcorso = function(boxName,codCountry){
                var suffix = ((boxName=='3X' && codCountry=='it') || ((boxName=='A' || boxName=='B1') && codCountry=='fr')) ? 'gif' : 'jpg';
                return "../assets/concorsoGame/"+boxName+"_"+codCountry+"."+suffix;
            };

            $rootScope.imgGameMemory = function(boxName,codCountry){
                return "../assets/gameMemory/EV_"+boxName+".jpg";
            };

            $rootScope.styleContactForm = function (id_template) {
                return (id_template == 2) ? "content_form_contact" : "marginTop40";
            };


            $rootScope.openErrorEmail = function () {
                var modalInstanceSucessEmail = $uibModal.open({
                    animation: true,
                    templateUrl: 'views/modal/modalErrorContactForm.html',
                    controller: 'ModalOkEmail',
                    windowClass: 'center-modal',
                    size: 'md'
                });

                modalInstanceSucessEmail.result.then(function (callBackItem) {
                });
            };

            $rootScope.openSuccessEmail = function () {
                var modalInstanceSucessEmail = $uibModal.open({
                    animation: true,
                    templateUrl: 'views/modal/modalSuccessContactForm.html',
                    controller: 'ModalOkEmail',
                    windowClass: 'center-modal',
                    size: 'md'
                });

                modalInstanceSucessEmail.result.then(function (callBackItem) {
                    $rootScope.homeButton();
                });
            };


            $rootScope.creditsModal = function (creditsText) {
                var modalInstanceCredits = $uibModal.open({
                    animation: true,
                    templateUrl: 'views/modal/modalCredits.html',
                    controller: 'ModalCredits',
                    windowClass: 'center-modal',
                    size: 'md',
                    resolve: {
                        creditsTextModal: function () {
                            return creditsText;
                        }
                    }
                });

                modalInstanceCredits.result.then(function (callBackItem) {
                });
            };

            $rootScope.showFrame = function () {
                //console.log("SHOW FRAME");
                $rootScope.frameShowContainer = true;
            };

            ///CAMBIAMENTO STATO
            $rootScope.$on('$stateChangeStart',
                function (event, toState, toParams, fromState, fromParams) {
                    //cfpLoadingBar.complete();
                    $rootScope.frameShowContainer = false;
                });

            /////ROOTSCOPE OPEN MODAL
            $rootScope.openModalMoreInfo = function (call, privacy) {
                var modalInstanceMoreInfo = $uibModal.open({
                    animation: true,
                    templateUrl: 'views/modal/modalMoreInfo.html',
                    controller: 'ModalIstanceMoreInfo',
                    size: 'md',
                    windowClass: 'center-modal',
                    resolve: {
                        call: function () {
                            return call;
                        },
                        privacy: function () {
                            return privacy;
                        }
                    }
                });

                modalInstanceMoreInfo.result.then(function (callBackItem) {
                });
            };

            $rootScope.countSendForm = 0;

            $rootScope.sendEmailContactForm = function (label,privacy, id_brand, parent) {
                document.activeElement.blur();
                if($rootScope.countSendForm==0){
                    //console.log("ID BRAND", id_brand);
                    //console.log("PARENT", parent);
                    var valid = true;
                    //console.log("VALID",valid);
                    if(valid){
                        var emailUtente = "";

                        /////CERCO L'EMAIL DELL'UTENTE
                        angular.forEach(label, function (value, key) {
                            //console.log(value.label);
                            if (value.label == 'Email')
                                emailUtente = value.value;
                        });

                        //////HTTP SEND CALL
                        $http({
                            method: 'POST',
                            url: globalConfig.urlServlet + 'sendFormBrand',
                            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                            data: {
                                'id_brand': id_brand,
                                'labels': label,
                                'privacy': privacy,
                                'text_response': parent.textResponse,
                                'id_language': $state.params.id_language,
                                'id_totem':(globalConfig.id_totem==null) ? 0 : globalConfig.id_totem,
                                'id_cover':$state.params.id_obj,
                                'email_utente': emailUtente
                            } //PARAMETRI PASSATI
                        }).success(function (data, status, headers, config) {
                            //console.log("DATA RETURN", data);
                            if (data.status == 'ok') {
                                $rootScope.openSuccessEmail();
                                $rootScope.$broadcast('sendedEmailContactForm',{});
                            }
                            else {
                                $rootScope.openErrorEmail();
                            }
                        })
                        .error(function(data, status, headers, config) {
                                $rootScope.openErrorEmail();
                        });
                    }
                }

                $rootScope.countSendForm++;
                if($rootScope.countSendForm==3)
                    $rootScope.countSendForm = 0;
            };

            /////SFONDO
            $rootScope.sfondo = function(settingLayout){
                console.log(settingLayout);
            };

            /////
            $rootScope.iFrameSRC = function (srcUrl,settingLayout) {
                //console.log("FRAME SRC", srcUrl);
                var id_template = (globalConfig.previewOnline == false) ? globalConfig.id_template : $state.params.id_template;
                //var replaceTotem = (globalConfig.id_totem==null || !globalConfig.statsCounter) ? "id_testl" : "id_totem"+globalConfig.id_totem;
                var replaceTotem = (globalConfig.id_totem==null) ? "id_testl" : "id_totem"+globalConfig.id_totem;
                var codeCountry = (settingLayout.code=='it') ? 'ita' : settingLayout.code;
                //////////////////
                srcUrl = srcUrl.replace("__id_code_language", codeCountry);
                srcUrl = srcUrl.replace("__id_totem", replaceTotem);
                srcUrl = srcUrl.replace("__id_template", id_template);
                srcUrl = srcUrl.replace("__statscounter", globalConfig.statsCounter);
                srcUrl = srcUrl.replace("__id_entry", $state.params.id_obj);
                srcUrl = srcUrl.replace("__id_group", globalConfig.id_brand);
                srcUrl = srcUrl.replace("__id_dealer", globalConfig.id_dealer);
                srcUrl = srcUrl.replace("__id_type", $state.params.id_entry_type);
                srcUrl = srcUrl.replace("__statscounter", globalConfig.statsCounter);
                $timeout.cancel($rootScope.timeoutReturnHome);
                $timeout.cancel($rootScope.timeoutReturnHomeFromDetail);
                var idleTimeConfig = (angular.isDefined(globalConfig.idleTimeIframe)) ? globalConfig.idleTimeIframe : globalConfig.hidleTime ;
                $rootScope.timeoutReturnHomeFromDetail = $timeout(callAtTimeout, idleTimeConfig*1000);
                return $sce.trustAsResourceUrl(srcUrl);
            };

            $rootScope.checkboxChangeValue = function(label){
                if($rootScope.countSendForm==0) {
                    //document.activeElement.blur();
                    //labelDef.accepted = (angular.isUndefined(labelDef.accepted)) ? false : labelDef.accepted;
                    label.accepted = (label.accepted) ? false : true;
                }

                $rootScope.countSendForm++;
                if($rootScope.countSendForm==3)
                    $rootScope.countSendForm = 0;
            };

            $rootScope.styleGlobal = function (setting) {
                if (setting!=null && angular.isDefined(setting) && angular.isDefined(setting.backgroundType) && !$rootScope.loadSettingGlobal) {
                    if (setting.backgroundType == 'color') {
                        $rootScope.loadSettingGlobal = true;
                        $rootScope.styleGlobalReturn = "background-color:" + setting.backgroundColor;
                    }
                    else if(setting.backgroundImage_obj!=null) {
                        var promiseImage = $rootScope.imageSource(setting.backgroundImage_obj.uri);
                        promiseImage.then(function(data) {
                            $rootScope.loadSettingGlobal = true;
                            $rootScope.styleGlobalReturn = "background: url(" + data + ") repeat;";
                        });
                    }
                }
            };

            $rootScope.fontClass = function (setting) {
                if(setting!=null && angular.isDefined(setting) && angular.isDefined(setting.fontType)) {
                    var font = "fontRci";
                    if(setting.fontType == 1)
                        font = 'fontArial';
                    else if(setting.fontType == 3)
                        font = 'fontNissan';
                    else if(setting.fontType == 4)
                        font = 'fontRenault';
                    return font;
                }
            };

            $rootScope.resizeElement = function (element, margin) {
                var object = (angular.isUndefined(element.box_obj)) ? element.id_box_obj : element.box_obj;
                var widthEl = (margin == 1) ? parseInt(object.width) - 20 : object.width;
                var heightEl = (margin == 1) ? parseInt(object.height) - 20 : object.height;

                return 'tiles_' + widthEl + 'x' + heightEl;
            };


            $rootScope.resizeElementBox = function (id_box_obj, margin) {
                var widthEl = (margin == 1) ? parseInt(id_box_obj.width) - 20 : id_box_obj.width;
                var heightEl = (margin == 1) ? parseInt(id_box_obj.height) - 20 : id_box_obj.height;

                return 'width:' + widthEl + 'px; height:' + heightEl + 'px';
            };

            /////BACK BUTTON
            $rootScope.backButton = function () {
                history.go(-1);
            };

            /////PRIVACY POPUP
            $rootScope.openPrivacyPopUp = function(privacyLabel){
                if($rootScope.countSendForm==0) {
                    if (privacyLabel.linkable == 1) {
                        ////OPEN POPUP
                        var modalPopUp = $uibModal.open({
                            animation: true,
                            templateUrl: 'views/modal/modalPrivacy.html',
                            controller: 'ModalInstancePrivacy',
                            size: 'md',
                            windowClass: 'center-modal',
                            resolve: {
                                privacyLabel: function () {
                                    return privacyLabel;
                                },
                                globalConfig: function () {
                                    return $rootScope.globalConfig;
                                }
                            }
                        });

                        modalPopUp.result.then(function (callBackItem) {
                        });
                    };
                }

                $rootScope.countSendForm++;
                if($rootScope.countSendForm==3)
                    $rootScope.countSendForm = 0;
            };

            //////TRADUZIONE LABEL CONTACT FORM
            $rootScope.traduceLabel = function (label) {
                if($rootScope.globalConfig){
                    if (label == 'Firstname') {
                        return $rootScope.globalConfig['TEXT_NAME'];
                    }
                    else if (label == 'Lastname') {
                        return $rootScope.globalConfig['TEXT_SURNAME'];
                    }
                    else if (label == 'Email') {
                        return $rootScope.globalConfig['TEXT_EMAIL'];
                    }
                    else if (label == 'SEND') {
                        return $rootScope.globalConfig['TEXT_SEND'];
                    }
                    else if (label == 'ERROR') {
                        return $rootScope.globalConfig['TEXT_ERROR_REQUIRED_FIELDS'];
                    }
                    else if (label == 'accept') {
                        return $rootScope.globalConfig['TEXT_ACCEPT'];
                    }
                    else if (label == 'not_accept') {
                        return $rootScope.globalConfig['TEXT_NOT_ACCEPT'];
                    }
                    else if (label == 'cancel') {
                        console.log("CANCEL",$rootScope.globalConfig);
                        return $rootScope.globalConfig['TEXT_CANCEL'];
                    }
                    else
                        return label;
                }
            };

            /////HOME BUTTON
            $rootScope.homeButton = function () {
                //if (globalConfig.previewOnline == false) {
                //    //console.log("HOME TOTEM");
                //    $state.go('totem', {}, {'reload': true})
                //}
                //else {
                var promise = configLoad.load();
                promise.then(
                    function (payload) {
                        var id_brand = (payload.data.id_totem==null) ? $state.params.id_brand : payload.data.id_brand;
                        var id_template = (payload.data.id_totem==null) ? $state.params.id_template : payload.data.id_template;
                        var language = ($state.params.language) ? $state.params.language : globalConfig.languageCodeMeteo;

                        if ($state.params.id_template == 2) { ///PREVIEW
                            $state.go('previewHpPortrait', {
                                id_template: id_template,
                                id_brand: id_brand,
                                id_language: $state.params.id_language,
                                id_layout: $state.params.id_layout,
                                language: language
                            }, {reload: true});
                        }
                        else {
                            $state.go('previewHpLandScape', {
                                id_template: id_template,
                                id_brand: id_brand,
                                id_language: $state.params.id_language,
                                id_layout: $state.params.id_layout,
                                language: language
                            }, {reload: true});
                        }
                        //}
                    });
            };

            ///CALL TO ACTION
            $rootScope.callToAction = function (call, id_template, privacy, forced) {
                if($rootScope.countSendForm==0) {
                    if (call.id_type == 2) { ///MORE INFO
                        $rootScope.openModalMoreInfo(call, privacy);
                    }
                    else {////REDIRECT PAGE
                        var language = ($state.params.language) ? $state.params.language : globalConfig.languageCodeMeteo;

                        if (id_template == 2) {
                            $state.go('previewDetailPortrait', {
                                'id_entry_type': call.page_id_entry,
                                'id_obj': call.id_page,
                                'id_template': $state.params.id_template,
                                'id_brand': $state.params.id_brand,
                                'id_language': $state.params.id_language,
                                'id_layout': $state.params.id_layout,
                                'language': language
                            }, {reload: true});
                        }
                        else {
                            $state.go('previewDetailLandScape', {
                                'id_entry_type': call.page_id_entry,
                                'id_obj': call.id_page,
                                'id_template': $state.params.id_template,
                                'id_brand': $state.params.id_brand,
                                'id_language': $state.params.id_language,
                                'id_layout': $state.params.id_layout,
                                'language': language
                            }, {reload: true});
                        }
                    }
                }
                ///////////////////////////
                /////////FORCED
                if(angular.isUndefined(forced)){
                    $rootScope.countSendForm++;
                    if($rootScope.countSendForm==3)
                        $rootScope.countSendForm = 0;
                }
                //console.log("CALL",call);
                //console.log("CALL",id_template);
            };

            //////////////
            //DESIGN BORDER
            $rootScope.designBorder = function (settingLayout) {
                return (angular.isDefined(settingLayout) && settingLayout!=null && settingLayout.border == 1) ? ";border: 3px solid " + settingLayout.borderColor + ";box-sizing:border-box;overflow:hidden;" : "";
            };

            function callAtTimeout() {
                $rootScope.homeButton();
                console.log("Timeout occurred");
            }

            //////DWELLTIME TIMEOUT
            function dwellTimeTimeout() {
                var promise = configLoad.load();
                promise.then(
                    function (payload) {
                        if(payload.data.proximity){
                            var language = ($state.params.language) ? $state.params.language : globalConfig.languageCodeMeteo;

                            if(globalConfig.id_template==2)
                                $state.go('proximityPortrait', {
                                    id_template: $state.params.id_template,
                                    id_brand: $state.params.id_brand,
                                    id_language: $state.params.id_language,
                                    id_layout: $state.params.id_layout,
                                    language: language
                                }, {'reload': true});
                            else
                                $state.go('proximityLandScape', {
                                    id_template: $state.params.id_template,
                                    id_brand: $state.params.id_brand,
                                    id_language: $state.params.id_language,
                                    id_layout: $state.params.id_layout,
                                    language: language
                                }, {'reload': true});
                        }
                        else
                            $rootScope.homeButton();
                    },
                    function (errorPayload) {
                        console.log("error", errorPayload);
                    });
            }

            $rootScope.timeoutReturnHome = null;
            $rootScope.timeoutRefleshHome = null;

            //var watcher = chokidar.watch(globalConfig.watchFile);
            //watcher.on('change', function(path) {
            //    console.log("CHANGE FILE CONFIG JSON");
            //    $rootScope.homeButton();
            //});

            $rootScope.timeCountPeople = 0;
            $rootScope.distanceCountPeople = 0;
            $rootScope.sendDistancePeople = false;
            $rootScope.counterFalsiPositivi = 0;

            ////WEBSOCKET
            if(globalConfig.id_totem!=null){
                $rootScope.ws = null;
                $rootScope.ws = $websocket.$new({
                    url: globalConfig.urlWebSocket,
                    maxTimeout : 5000,
                    reconnect: true,
                    reconnectInterval: 100000
                }); // instance of ngWebsocket, handled by $websocket service
            }
            else
                $rootScope.ws = null;

            /////////////ALGORITMO DISTANZA PERSONA
            //if(globalConfig.id_totem!=null && globalConfig.statsCounter){
            //    //////////////CONTROLLO SE SIA AL DI FUORE DEL RANGE
            //    $rootScope.ws.$on('$message',function (message) {
            //        /////////ALGORITMO
            //        console.log("DISTANZA ==> PARAMETRI",parseInt(message),$rootScope.timeCountPeople,$rootScope.distanceCountPeople);
            //        ///////ALGORITMO distanza
            //        var distanzaInt = parseInt(message);
            //        if(distanzaInt!=4 && distanzaInt!=999){ ////casi di errore
            //            ////controllo il range di distanza
            //            if(distanzaInt>parseInt(globalConfig.countProximityFrom) && distanzaInt<globalConfig.countProximityTo){
            //                if($rootScope.distanceCountPeople==0 || (Math.abs(distanzaInt-$rootScope.distanceCountPeople)>parseInt(globalConfig.deltaPositionProximity))) {
            //                    console.log("TROVATA NUOVA PERSONA");
            //                    $rootScope.timeCountPeople = Math.round(new Date().getTime() / 1000);
            //                    $rootScope.distanceCountPeople = distanzaInt;
            //                    $rootScope.sendDistancePeople = false;
            //                    $rootScope.counterFalsiPositivi = 0;
            //                }
            //                else if((Math.abs(distanzaInt-$rootScope.distanceCountPeople)<=parseInt(globalConfig.deltaPositionProximity))
            //                    && Math.abs($rootScope.timeCountPeople-Math.round(new Date().getTime() / 1000))>=parseInt(globalConfig.timeCountProximity)){
            //                    console.log("STESSA PERSONA");
            //                    $rootScope.counterFalsiPositivi = 0;
            //                    /////////////////
            //                    /////
            //                    if(!$rootScope.sendDistancePeople){
            //                        ////chiamata post
            //                        $http({
            //                            method: 'GET',
            //                            url: globalConfig.urlServlet + 'statsCounterPeople?id_totem='+globalConfig.id_totem,
            //                            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            //                        }).success(function (data, status, headers, config) {
            //                            console.log("DATA STATISTICA INVIATA", data);
            //                        });
            //                        $rootScope.sendDistancePeople = true;
            //                    }
            //                    //else{
            //                    //    console.log("NON INVIO LA STATISTICA PERCHE' ONLINE");
            //                    //}
            //                }
            //            }
            //            else if($rootScope.counterFalsiPositivi>=3){
            //                console.log("RESETTO PERCHE' USCITO");
            //                $rootScope.timeCountPeople = 0;
            //                $rootScope.distanceCountPeople = 0;
            //                $rootScope.sendDistancePeople = false;
            //                $rootScope.counterFalsiPositivi = 0;
            //            }
            //            else{
            //                console.log("INCREMENTO I FALSI POSITIVI",$rootScope.counterFalsiPositivi);
            //                $rootScope.counterFalsiPositivi++;
            //            }
            //        }
            //    });
            //}

            $rootScope.reloadPage = function(){
                $state.reload($state.current.name,$state.params);
            };

            if($state.current.name!='totem'){
                $rootScope.reloadPage();
            }

            /////CARICAMENTO DEI CONTENUTI, LA UTILIZZO PER FORZARE L'INVIO DELLE STATISTICHE
            $rootScope.$on('$viewContentLoaded',
                function(event){
                    $rootScope.sendStatistiche = true;
                });

            /////WATCH STATE
            $rootScope.$on('$stateChangeSuccess',
                function (event, toState, toParams, fromState, fromParams) {
                    var promise = configLoad.load();
                    promise.then(
                        function (payload) {
                            //console.log("STATE CHANGE SUCCESS");
                            //console.log("RICARICO I DATI",payload.data);
                            //////reload dati
                            ////JQUERY BROADCAST CHANGE PAGE
                            resetGameForced(true);
                            // $element.trigger('changePageAll', {});

                            var timeConfig = globalConfig.last_update;
                            globalConfig = payload.data;
                            // do something
                            $rootScope.loadImageCounter = 0;
                            $rootScope.loadSettingGlobal = false;
                            $scope.params = fromParams;
                            var homePage = toState.name == 'previewHpPortrait' || toState.name == 'previewHpLandScape';
                            var fullproximity = fromState.name=='proximityPortrait' || fromState.name=='proximityLandScape';

                            if(!homePage && $rootScope.ws!=null){
                                $rootScope.ws.$un('$message');
                            }

                            ////RITORNO HOME PER SENSORI
                            if($rootScope.timeoutReturnHome){
                                $timeout.cancel($rootScope.timeoutReturnHome);
                                $rootScope.timeoutReturnHome=null;
                            }
                            ////REFLESH HOME
                            if($rootScope.timeoutRefleshHome)
                                $timeout.cancel($rootScope.timeoutRefleshHome);

                            //////RETURN HOME FROM DETAIL
                            if($rootScope.timeoutReturnHomeFromDetail){
                                $timeout.cancel($rootScope.timeoutReturnHomeFromDetail);
                                $rootScope.timeoutReturnHomeFromDetail=null;
                            }


                            if (toState.name != 'previewHpPortrait' && toState.name != 'previewHpLandScape' && toState.name != 'proximityPortrait' && toState.name != 'proximityLandScape' && toState.name != 'previewFullVideoPortrait' && toState.name != 'previewFullVideoLandScape'){
                                //var timeHidle = (angular.isDefined($stateParams.id_entry_type) && $stateParams.id_entry_type!=18) ? globalConfig.hidleTime : 600;
                                // if(angular.isUndefined(toParams.id_entry_type)){
                                    console.log("HO IMPOSTO IL RITORNO HOME",globalConfig.hidleTime);
                                    // $rootScope.timeoutReturnHome = $timeout(callAtTimeout, globalConfig.hidleTime*1000); ////3 minuti
                                    $rootScope.timeoutReturnHomeFromDetail = $timeout(callAtTimeout, globalConfig.hidleTime*1000); ////3 minuti
                                // }
                            }

                            ////REFLESH PER L'HP se ho un aggiornamento
                            //if (homePage && !globalConfig.proximity){
                            if (homePage){
                                $rootScope.timeoutRefleshHome = $timeout(callAtTimeout, globalConfig.refleshHomePageTime*1000); ////10 minuti reflesh HP
                            }
                            /////PROXIMITY FUNCTION
                            if(homePage && globalConfig.proximity){
                                if($rootScope.ws==null){
                                    $rootScope.timeoutReturnHome = $timeout(dwellTimeTimeout, globalConfig.dwellTime*1000);
                                }
                                else{
                                    //////////////CONTROLLO SE SIA AL DI FUORE DEL RANGE
                                    $rootScope.ws.$on('$message',function (message) {
                                        if(parseInt(message)>globalConfig.proximityTo && $rootScope.timeoutReturnHome==null){
                                            $rootScope.timeoutReturnHome = $timeout(dwellTimeTimeout, globalConfig.dwellTime*1000);
                                        }
                                        else if(parseInt(message)>globalConfig.proximityFrom && parseInt(message)<globalConfig.proximityTo){
                                            $timeout.cancel($rootScope.timeoutReturnHome);
                                            $rootScope.timeoutReturnHome = null;
                                        }
                                    });
                                }
                            }

                            if ((!homePage || fullproximity) && $rootScope.sendStatistiche && globalConfig.id_totem!=null && globalConfig.statsCounter) {
                                $rootScope.sendStatisticheAPI(toParams,toState,'changePage');
                            }
                        },
                        function (errorPayload) {
                            console.log("error", errorPayload);
                        });
                });

                $rootScope.resetReturnHome = function(){
                    if($rootScope.timeoutReturnHomeFromDetail){
                        console.log("CANCEL RETURN HOME",globalConfig.hidleTime);
                        $timeout.cancel($rootScope.timeoutReturnHomeFromDetail);
                        $rootScope.timeoutReturnHomeFromDetail = $timeout(dwellTimeTimeout, globalConfig.hidleTime*1000);
                    }
                };

                $rootScope.sendStatisticheAPI = function(toParams,toState,type){
                    if($rootScope.sendStatistiche){
                        //////HTTP SEND CALL
                        var id_entry_type = (angular.isUndefined(toParams.id_entry_type)) ? -1 : toParams.id_entry_type;
                        id_entry_type = (toState.name == 'previewContactFormLandScape' || toState.name == 'previewContactFormPortrait') ? 12 : id_entry_type;
                        var id_entry = (angular.isUndefined(toParams.id_obj)  || toParams.id_obj==-99|| toParams.id_obj=='') ? -1 : toParams.id_obj;
                        var hP = (toState.name == 'previewHpPortrait' || toState.name == 'previewHpLandScape') ? 1 : 0;
                        var FP = (toState.name=='proximityPortrait' || toState.name=='proximityLandScape') ? 1 : 0;
                        var dataSend = {
                            'id_brand': $state.params.id_brand,
                            'id_totem': globalConfig.id_totem,
                            'id_entry_type': id_entry_type,
                            'id_entry': id_entry,
                            'hP': hP,
                            'FP': FP,
                            'id_dealer': globalConfig.id_dealer
                        };
                        ////chiamata post
                        $http({
                            method: 'POST',
                            url: globalConfig.urlServlet + 'statsclick',
                            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                            data: dataSend //PARAMETRI PASSATI
                        }).success(function (data, status, headers, config) {
                                //console.log("DATA RETURN CHANGE STATE", data);
                            })
                            .error(function (data, status, headers, config) {
                                /////////statsLocal
                                statsLocal.dbConnect(globalConfig.id_totem);
                                statsLocal.put('stats__'+new Date().getTime().toString(),dataSend);
                            });

                        $rootScope.sendStatistiche = false;
                    }
                }

        },
        function (errorPayload) {
            console.log("error", errorPayload);
        });

        $rootScope.$on('loadImage', function(event, args) {
                $rootScope.loadImageCounter++;
                //console.log("Arrived load",$rootScope.loadImageCounter);
            }
        );

        $rootScope.logTouch = function(){
            console.log("TOUCHED");
        }
};

module.exports = BaseController;