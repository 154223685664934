"use strict";

exports.__esModule = true;

var _classCallCheck2 = require("babel-runtime/helpers/classCallCheck");

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Hub = function Hub(file, options) {
  (0, _classCallCheck3.default)(this, Hub);

  this.file = file;
  this.options = options;
};

exports.default = Hub;
module.exports = exports["default"];