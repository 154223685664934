"use strict";

var ModalInstancePrivacy = function ($scope, $rootScope, $location, config,$http,$sce,$uibModalInstance,privacyLabel,globalConfig,configLoad,apiFactory) {
    $scope.globalConfig = globalConfig;
    $scope.privacyLabel = privacyLabel;

    $scope.cancel = function () {
        $uibModalInstance.close("cancel");
    };

    ///CAMBIAMENTO STATO
    $rootScope.$on('$stateChangeStart',
        function (event, toState, toParams, fromState, fromParams) {
            $uibModalInstance.close("cancel");
        });
};

module.exports = ModalInstancePrivacy;