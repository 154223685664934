"use strict";
var fs = require('fs');
var path = require('path');

var ConfigLoad = function($http,$q) {
    return {
        load: function (language,all) {
            var returnJson = {'data':{}};
            return $q(function (resolve, reject) {
                $http({
                    method: 'GET',
                    url: '../assets/config/config.json'
                }).success(function (data, status, headers, config) {
                    returnJson['data'] = data;
                    if(!all && language){
                        language = (language) ? language : data.languageCodeMeteo;
                        delete data.languageCodeMeteo;
                        $http({
                            method: 'GET',
                            url: '../assets/language/'+language+'.json'
                        }).success(function (data, status, headers, config) {
                            angular.extend(returnJson['data'],data);
                            $http({
                                method: 'GET',
                                url: '../assets/version/version.json'
                            }).success(function (data, status, headers, config) {
                                returnJson['data']['version'] = data['version'];
                                resolve(returnJson);
                            });
                        });
                    }
                    else{
                        $http({
                            method: 'GET',
                            url: '../assets/version/version.json'
                        }).success(function (data, status, headers, config) {
                            returnJson['data']['version'] = data['version'];
                            resolve(returnJson);
                        });
                    }
                });
            });
        }
    };
};

module.exports = ConfigLoad;