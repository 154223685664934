"use strict";

var fs = require('fs');
var path = require('path');

var TtmVideo = function () {
};

TtmVideo.prototype.scope = {
    object : '=',
    setting : '=',
    idParent : '=',
    box: '=',
    margin: '=',
    base: '=',
    active: '=',
    loop: '='
};

TtmVideo.prototype.template = fs.readFileSync(path.resolve('views/directives/Video.template'), 'utf8');

TtmVideo.prototype.link = function (scope, element, attrs, controller, transcludeFn) {
    // scope.loop = (scope.loop) ? true : false;
    //console.log("OBJECT VIDEO",scope.object);
    //console.log("OBJECT SETTING",scope.setting);
};

TtmVideo.prototype.restrict = 'E';

module.exports = TtmVideo;