"use strict";
var fs = require('fs');
var path = require('path');
var PouchDB = require('pouchdb');

var ApiFactory = function($http,$q) {
    //https://totem:totem01@rciapp.3punto6.com/appdb/app__db__48
    var dbName = '';
    var localDB = null;
    var remoteDB = null;
    var dataFactory = {};
    var previewValue = 0;
    var random = {};

    /////////DB CONNECT
    dataFactory.dbConnect = function(id_group,preview,id_totem,id_template){
        var dbNameNew = (!preview) ? 'app__db__'+id_group+'__'+id_template : 'app__db__'+id_group+'__'+id_template+'__preview';
        if(id_totem!=null){
            previewValue = (preview) ? 1 : 0;
        }
        else{
            previewValue = -1;
        }
        ///////MI COLLEGO NUOVAMENTE SOLO SE MI VOGLIO COLLEGARE AD UN NUOVO DB, SOLO SE NON E' UNA PREVIEW LEGATA AL CMS
        if(dbName!=dbNameNew && previewValue != -1){
            localDB =  new PouchDB('http://localhost:5984/'+dbNameNew);
            dbName=dbNameNew;
            //localDB =  new PouchDB('http://totem.linkomm.net:5984/'+dbNameNew);
        }
    };

    //////API FACTORY
    dataFactory.api = function(urlServlet,type,object,preview){
        //console.log("INSIDE IT");
        urlServlet = urlServlet+"&preview="+previewValue;

        return $q(function (resolve, reject) {
            if(previewValue!=-1){ ///diverso da preview online
                localDB.get(object).then(function (message) {
                    //console.log("OBJECT PRESENT COUCH",dbName,object,message);
                    resolve(message.toApp);
                }).catch(function (err) {
                    if (err.status == 404) {
                        ///chiamata API
                        //console.log("INVIO URL", object,urlServlet);
                        $http({
                            method: 'GET',
                            url: urlServlet
                        }).success(function (data, status, headers, config) {
                            resolve(data);
                        });
                    }
                });
            }
            else{
                $http({
                    method: 'GET',
                    url: urlServlet
                }).success(function (data, status, headers, config) {
                    resolve(data);
                });
            }
        });
    };

    //////API SOURCE IMAGE
    dataFactory.image = function(urlImage){
            var urlImageCouch = (angular.isDefined(urlImage)) ? urlImage.replace(/\//g,"--") : '';

            if(angular.isDefined(urlImage)){
                var urlImageCouchSave = angular.copy(urlImageCouch);
                var imageSplit = urlImageCouchSave.split("--");
                var imageName = imageSplit[Object.keys(imageSplit).length-1];
            }

            return $q(function (resolve, reject) {
                if(urlImageCouch==''){
                    resolve('undefined');
                }
                else{
                    if(previewValue!=-1){ ///diverso da preview online
                        localDB.get(urlImageCouch,{attachments: true}).then(function (blob) {
                            var url = 'http://localhost:5984/'+dbName+'/'+urlImageCouch+'/'+imageName;
                            resolve(url);
                        }).catch(function (err) {
                            if (err.status == 404) {
                                resolve(urlImage);
                            }
                        });
                    }
                    else{
                        resolve(urlImage);
                    }
                }
            });
        };


    //////RANDOM RETURN
    dataFactory.random = function(baseServlet,id_brand,id_template,id_dealer){
        return $q(function (resolve, reject){
            //////BASE
            var promiseRandomBase = dataFactory.api(baseServlet+'random?id_brand='+id_brand+'&entry_type=6&id_template='+id_template+'&preview='+previewValue,'random','random__6__'+id_brand+'__0');
            promiseRandomBase.then(function(data) {
                random[6] = data.message;
                ///////PREMIUM
                var promiseRandomPremium = dataFactory.api(baseServlet+'random?id_brand='+id_brand+'&entry_type=7&id_template='+id_template+'&preview='+previewValue,'random','random__7__'+id_brand+'__0');
                promiseRandomPremium.then(function(data) {
                    random[7] = data.message;
                    ///////DEALER
                    var promiseRandomDealer = dataFactory.api(baseServlet+'random?id_brand='+id_brand+'&entry_type=5&id_template='+id_template+'&id_dealer='+id_dealer+'&preview='+previewValue,'random','random__5__'+id_brand+'__'+id_dealer);
                    promiseRandomDealer.then(function(data) {
                        random[5] = data.message;
                        resolve(random);
                    });
                });
            });
        });
    };

    return dataFactory;
};

module.exports = ApiFactory;