"use strict";

var fs = require('fs');
var path = require('path');

var TtmHomePagePortraitPrivilege = function () {
    //this.compile = $compile;
};

TtmHomePagePortraitPrivilege.prototype.scope = {
    rows : '=',
    globalConfig : '=',
    settingTemplate : '=',
    idTemplate : '=',
    templateurl : '=',
    idLanguage : '=',
    margin : '=',
    random: '='
};


TtmHomePagePortraitPrivilege.prototype.template = fs.readFileSync(path.resolve('views/home/4_portrait.template'), 'utf8');
TtmHomePagePortraitPrivilege.prototype.restrict = 'E';
module.exports = TtmHomePagePortraitPrivilege;
