"use strict";

var fs = require('fs');
var path = require('path');

var TtmVideoPlayer = function () {
};

TtmVideoPlayer.prototype.scope = {
    url : '@',
    autoplay: '@',
    mute: '@',
    loop: '@',
    controls : '@',
    relative: '='
};

TtmVideoPlayer.prototype.template = fs.readFileSync(path.resolve('views/directives/videoPlayer.template'), 'utf8');

TtmVideoPlayer.prototype.controller = function($scope){
};

TtmVideoPlayer.prototype.link = function (scope, element, attrs, controller, transcludeFn) {
    scope.controls = angular.isDefined(scope.controls) && scope.controls==='true';
    scope.autoplay = angular.isDefined(scope.autoplay) && scope.autoplay==='true';
    scope.loop = angular.isDefined(scope.loop) && scope.loop==='true';
    scope.mute = angular.isDefined(scope.mute) && scope.mute==='true';
};

TtmVideoPlayer.prototype.restrict = 'E';

module.exports = TtmVideoPlayer;