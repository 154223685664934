"use strict";

var fs = require('fs');
var path = require('path');

var TtmSwipe = function () {
};

TtmSwipe.prototype.scope = {
    innerFor: '=',
    innerClass: "=",
    innerEvent: "&"
};

TtmSwipe.prototype.template = fs.readFileSync(path.resolve('views/directives/swipe.template'), 'utf8');

TtmSwipe.prototype.restrict = 'E';

module.exports = TtmSwipe;