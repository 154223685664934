"use strict";

var fs = require('fs');
var path = require('path');

var TtmFooter = function () {
};

TtmFooter.prototype.scope = {
    innerFor: '=',
    innerClass: "=",
    margin: "=",
    settingTemplate: "=",
    idTemplate: "=",
    innerEvent: "&",
    videoSidebar: "=?",
    credits: "=",
    creditsText :"=",
    globalConfig :"="
};

TtmFooter.prototype.template = fs.readFileSync(path.resolve('views/directives/sidebar.template'), 'utf8');

TtmFooter.prototype.restrict = 'E';

module.exports = TtmFooter;